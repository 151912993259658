.main {
  height: 100%;
  overflow-y: auto;
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 5px;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555555ac;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* phone number input */
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 0 !important;
  border-right: 1px solid #cacaca !important;
}

.react-tel-input .form-control {
  padding-left: 45px !important;
}

.country-list {
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2) !important;
}

.country-list li input {
  margin-left: 0 !important;
}

.country-list li {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

/* country state city dropdown css */
.select__indicator svg {
  width: 1.5rem;
  height: 1.5rem;
}

.select__control {
  outline: none;
  font-size: 1.4rem;
  padding-left: 0.6rem;
  border: 0.15rem solid #ebebeb !important;
  border-color: #ebebeb !important;
}

.select__control.select__control--is-focused {
  outline: 0.15rem solid #ebebeb !important;
  border-color: #ebebeb10 !important;
}

._3IfyxregionDDContainer,
._3IfyxregionDDContainerError {
  width: 100%;
  font-size: 1.4rem;
  margin: 0.2rem 0;
  outline: none;
  font-family: "Source Sans Regular" !important;
  color: #131313;
  border-radius: 4px !important;
}

._3IfyxregionDDContainer .select__control {
  height: 4.8rem;
}

._3IfyxregionDDContainerError {
  border: 1.5px solid red !important;
}

._3IfyxDashboardRegionDD,
._3IfyxDashboardRegionDDError {
  width: 100% !important;
  font-size: 1.4rem;
  margin: 0.2rem 0;
  outline: none;
  font-family: "gilroyMedium" !important;
  color: #131313;
  border-radius: 4px !important;
}

._3IfyxDashboardRegionDD .select__control,
._3IfyxDashboardRegionDDError .select__control {
  width: 100% !important;
  height: 4.2rem;
  border: 0.13rem solid #97979770 !important;
  border-color: #97979770 !important;
}

._3IfyxDashboardRegionDDError {
  border: 0.15rem solid red !important;
}

.MuiTimeClock-root {
  font-size: 1.4rem;
}

/* document viewer */
#react-doc-viewer #header-bar {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-family: "gilroyRegular";
  margin-left: 0;
  background-color: #eeeeee;
  padding: 0.8rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  min-height: unset;
}

#react-doc-viewer #header-bar #doc-nav-info {
  margin: 0.2em 1em;
}

#react-doc-viewer #header-bar #doc-nav-next,
#react-doc-viewer #header-bar #doc-nav-prev {
  width: 25px;
  height: 25px;
}

/* pdf viewer */
.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf {
  --scale-factor: 0 !important;
  background-color: 0 !important;
  position: 0 !important;
  min-width: auto !important;
  min-height: auto !important;
}

.react-pdf__Document {
  border: 1px solid #efefef;
  --scale-factor: 0 !important;
}

.react-pdf__Page__canvas {
  /* height: 16rem !important;
  width: 12rem !important;
  margin-left: auto !important;
  margin-right: auto !important; */
}

.react-pdf__Page__annotations {
  width: auto !important;
  height: auto !important;
}

/* mui Auocomplete dropdown styles */
.MuiAutocomplete-listbox {
  max-height: 150px !important;
  font-family: "gilroyMedium" !important;
  font-size: 1.4rem;
}

/* graph */
.apexcharts-datalabels-group {
  height: 100px !important;
  transform: 0 !important;
}

.apexcharts-text {
  font-family: "gilroyMedium" !important;
  font-size: 1.2rem !important;
}

.apexcharts-tooltip {
  background-color: #000 !important;
  color: #fff !important;
}

.apexcharts-active {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
}

/* phone number input global style */
.intl-tel-input {
  width: 100%;
  /* z-index: 3000 !important; */
}

.intl-tel-input .selected-flag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 79px;
  border-right: 1px solid #cacaca50 !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 0 !important;
  padding: 6px !important;
  font-size: 12px !important;
  background-color: transparent !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type="text"],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type="tel"] {
  width: 100% !important;
  padding-left: 85px !important;
  height: 46px !important;
  border: 1px solid #cacaca;
  border-radius: 6px !important;
}

.intl-tel-input .form-control {
  width: 100% !important;
}

.intl-tel-input .country-list {
  padding: 10px 0 !important;
  width: 100% !important;
}

.intl-tel-input .country-list li {
  font-size: 14px !important;
}

.iti-mobile .intl-tel-input.iti-container {
  z-index: 3000 !important;
}

@media only screen and (max-width: 1625px) {
  .intl-tel-input {
    max-width: 41% !important;
    min-width: 270px !important;
  }

  .iti-mobile .intl-tel-input.iti-container {
    transform: translate(59%, -1%);
  }
}

@media only screen and (max-width: 1584px) {
  .intl-tel-input {
    max-width: 56%;
    min-width: 270px;
    /* z-index: 3000 !important; */
    /* -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
  }

  .iti-mobile .intl-tel-input.iti-container {
    transform: translate(72%, -1%);
  }
}

@media only screen and (max-width: 427px) {

  .iti-mobile .intl-tel-input.iti-container {
    transform: translate(-1%, -1%);

  }
}