/* side wrapper */
.sidebar_wrapper_main,
.sidebar_wrapper_main_closed {
  width: 19%;
  position: relative;
  min-height: 100vh;
  transition: all 0.2s ease-in-out;
}
.sidebar_wrapper,
.sidebar_wrapper_closed {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  height: 100%;
  max-width: 320px;
  padding: 2.5rem 0;
  transition: all 0.2s ease-in-out;
}
.sidebar_wrapper_closed {
  width: 0% !important;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.sidebar_wrapper_main_closed {
  width: 1% !important;
  transition: all 0.2s ease-in-out;
}
.sidebar_wrapper.mainSideBar,
.sidebar_wrapper_closed.mainSideBar {
  background: linear-gradient(
    -140deg,
    var(--sidebar-bg-blue) 20%,
    var(--sidebar-bg-pink)
  );
}
.sidebar_wrapper.buyerSideBar,
.sidebar_wrapper_closed.buyerSideBar {
  background: var(--buyerSideBar-bg-light-blue);
}
.sidebar_wrapper.vendorSideBar,
.sidebar_wrapper_closed.vendorSideBar {
  background: linear-gradient(
    -140deg,
    var(--vendorSideBar-bg-primary-blue) 30%,
    var(--vendorSideBar-bg-secondary-blue)
  );
}
/* sideNavLogo img */
.sideNavLogo {
  margin: 0 0 2.5rem 3rem;
}
/* side bartab */
.sidebar_tab {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0rem 1rem 3rem;
  cursor: pointer;
  margin: 0.7rem 0;
  color: var(--text-light);
  text-decoration: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  border-right: 1px solid transparent;
}
.sidebar_wrapper .buyer_sideBar {
  color: #000;
  border-left: 2px solid transparent;
}
.sidebar_tab img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.sidebar_tab p {
  margin-left: 0.8rem;
  font-size: 1.6rem;
  font-family: "gilroyMedium";
}
.sidebar_tab_active,
.buyer_sidebar_tab_active,
.Vendor_sidebar_tab_active {
  transition: all 0.2s ease-in-out;
  color: var(--dashboard-darkblue) !important;
  border-left: 6px solid var(--dashboard-darkblue) !important;
}
.sidebar_tab_active p,
.buyer_sidebar_tab_active p,
.Vendor_sidebar_tab_active p {
  font-family: "gilroySemibold" !important;
}
.sidebar_tab_active {
  background-color: var(--sidebar-tab-hover);
}
.buyer_sidebar_tab_active {
  background-color: var(--buyerSideBar-bg-active-blue);
}
.Vendor_sidebar_tab_active {
  background-color: var(--vendorSideBar-bg-active-blue);
}
.openSideMenu {
  display: none;
  margin: 1rem;
}
.closeBtn {
  position: absolute;
  right: 0;
  top: 3.2rem;
  border: none;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff50;
}
.sideBarToggleBtn,
.sideBarToggleBtnOpen {
  position: absolute;
  right: 0;
  top: 3.2rem;
  border: none;
  font-size: 3.5rem !important;
  border-radius: 6px;
  padding: 0.4rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
  background-color: #ffffff50;
  transition: all 0.2s ease-in-out;
}
.sideBarToggleBtnOpen {
  transform: rotate(180deg) translateX(-20px);
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.08);
}
.prg_sidebar_logo div {
  margin: 0 !important;
}
.prg_sidebar_logo {
  width: 12rem;
}
.menuBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}
.menuBar span {
  font-size: 1.6rem;
  font-family: "gilroySemiBold";
  margin-left: 1rem;
}

/* responsive section */

@media (min-width: 1024px) {
  .menuDraw {
    display: none;
  }
}
@media (max-width: 1024px) {
  .sidebar_wrapper_main,
  .sidebar_wrapper_main_closed {
    width: 100% !important;
    min-height: auto !important;
  }
  .sideBarToggleBtn,
  .sideBarToggleBtnOpen {
    display: none !important;
  }
  .openSideMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    margin: 0;
    height: 60px;
    z-index: 9;
    padding: 1rem;
    border-bottom: 1px solid #adadad50;
  }
  .tempSideBar {
    display: none;
  }
  .sidebar_wrapper {
    min-width: 280px;
    max-width: 320px;
  }
}
@media (max-width: 480px) {
  .prg_sidebar_logo {
    width: 10rem;
  }
  .menuBar {
    padding: 0.5rem 1.5rem;
  }
  .menuBar span {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
}
