.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_success_popup_wrapper {
  width: 62rem;
  height: 37rem;
  background-color: var(--bg-light);
  border-radius: 1rem;
  padding: 0 2rem;
  padding-top: 1rem;
  /* padding: 0 2rem 2rem 2rem; */
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.popup_image {
  width: 22%;
  margin-bottom: 2rem;
}
.popup_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup_text {
  flex-direction: column;
}
.popup_text .primary {
  font-size: 2.4rem;
  font-family: "gilroyBold";
}
.popup_text p {
  font-size: 1.6rem;
  max-width: 30rem;
  font-family: "GilroyMedium";
  color: #3a3a3c71;
  margin: 0;
  margin-bottom: 1rem;
}
p.pDescLG {
  width: 80%;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: #555;
}
@media (max-width: 650px) {
  .login_success_popup_wrapper {
    width: 90% !important;
    height: 30rem !important;
  }
  .signupText .primary {
    font-size: 2rem;
  }
  .signupText p {
    margin-bottom: 1.5rem;
  }
}
