.searchInput {
    border: none;
}
.searchInput input{
    width: 100%;
    border: none !important;
    background-color: transparent;
    border-radius: 3px;
    font-size: 1.4rem;
    cursor: auto;
    border-radius: 0.6rem;
}
.borderRed{
    border: 0.15rem solid red !important;
}
.searchInput input + fieldset {
    border: none !important;
}
.pRelative{
    position: relative;
}
.dropDown{
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: 1300;

    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.6326530612244896rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    overflow: auto;
    max-height: 150px !important;
}
.dropDown li{
    min-height: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    padding-top: 6px;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
}
.dropDown li:hover{
    background-color: rgba(0, 0, 0, 0.04)
}
@media (min-width: 600px){
    .dropDown li {
        min-height: auto;
    }
}