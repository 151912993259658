.textarea_box textarea {
    font-family: var(--regular);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    color: #000 !important;
    border: 1px solid #C4C5C6;
    box-shadow: 0px 0px 1.8px #C4C5C6;
    border-radius: 3px;
    resize: initial;
    padding: 12px 12px;
    width: 524px;
}

.textarea_box {
    position: relative;
}

.texalign_end {
    text-align: end;
    margin: 0;
    padding: 0px;
    font-size: 12px;
}

.textarea:focus-visible {
    outline: none !important;
}

.textarea_box label {
    font-family: var(--regular);
    position: absolute;
    background: #fff;
    font-weight: 400;
    color: #7F8389;
    font-size: 12px;
    left: 10px;
    top: -6px;
    padding: 0px 4px;
}