.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lineSkeleton {
  width: 100%;
  height: 100%;
}
.roundSkeleton {
  width: 50%;
  height: 100%;
}
.graphCard,
.barsCard {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  /* width: 49%;
  max-width: 71rem; */
  height: minmax(273px, 402px);
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.linearProgress {
  margin-bottom: 3rem;
}
.linearProgress,
.linearProgressInline {
  width: 60%;
}
.indicators {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.linearProgress span,
.linearProgressInline span {
  font-size: 18px;
  font-family: "gilroySemiBold";
}
.chartWrap,
.chartWrapDonut {
  position: relative;
}
.chartWrap .info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  font-family: "gilroyBold" !important;
  font-size: 1.4rem !important;
}
.chartWrapDonut .info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "gilroyBold" !important;
  font-size: 2.4rem !important;
}
.chartWrapDonut .infoSm {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "gilroyBold" !important;
  font-size: 1.4rem !important;
}
.monthPicker {
  display: flex;
  align-items: center;
  justify-content: center;
}
.monthPicker span {
  font-family: "gilroyBold";
  font-size: 12px;
  margin-right: 1rem;
}
.duration {
  width: 89px;
  height: 10px;
}
.linearProgressInline {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.barsCard {
  align-items: flex-start;
  flex-direction: column;
}
.filterDropdown {
  height: 2.7rem;
  /* width: 8.9rem; */
  border-radius: 6px;
  font-family: "gilroyMedium";
  font-size: 1rem;
  color: #000;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  outline: none;
}
.filterDropdown::-ms-expand {
  display: none !important;
}
/* @media (min-width: 1700px) {
  .graphCard,
  .barsCard {
    justify-content: flex-start;
    margin-right: 2rem;
  }
}
@media (max-width: 1440px) {
  .graphCard,
  .barsCard {
    width: 524px;
  }
} */

@media (max-width: 480px) {
  .graphCard,
  .barsCard {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
  .linearProgress,
  .linearProgressInline {
    width: 100%;
  }
  .indicators {
    width: 100%;
    flex-wrap: wrap;
  }
}
