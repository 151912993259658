.inputContainer {
  margin: 1rem 0 1rem 1rem;
  position: relative;
}
.label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 5px;
  color: #131313;
  font-family: "Source Sans Regular";
}
.disabled {
  opacity: 0.7;
}
.input,
.select,
.mailOtpContainer,
.pwdField {
  width: 100%;
  display: block;
  padding: 1.5rem;
  font-size: 1.4rem;
  height: 4.8rem;
  border: 0.15rem solid #ebebeb;
  border-radius: 4px;
  margin: 0.2rem 0;
  color: #131313;
  outline: none;
  font-family: "Source Sans Regular";
}
.inputTextArea {
  width: 100%;
  display: block;
  padding: 1.5rem;
  font-size: 1.4rem;
  height: 12rem;
  border: 0.15rem solid #ebebeb;
  border-radius: 4px;
  margin: 0.2rem 0;
  color: #131313;
  outline: none;
  font-family: "Source Sans Regular";
}
.input:disabled,
.inputTextArea:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.select {
  padding: 0;
  border: 0.15rem solid #ebebeb;
}
.error {
  border: 0.15rem solid red;
  font-family: "Source Sans Regular";
  border-radius: 4px;
}
.errorText {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: "Source Sans Regular";
}
.regionDropDown {
  padding: 1rem !important;
  height: 100% !important;
  border: 0 !important;
  outline: 0 !important;
  color: #131313;
}
.phoneInputStyle {
  width: 100% !important;
  height: 4.8rem !important;
  font-size: 1.4rem !important;
  border: 0.15rem solid #ebebeb !important;
  margin: 0.2rem 0 !important;
  outline: none !important;
  color: #131313;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
}
.phoneInputContainerStyle {
  width: 100% !important;
  height: 4.8rem !important;
  font-size: 1.4rem !important;
  margin: 0.2rem 0 !important;
  outline: none !important;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
  color: #131313;
}
.phoneInputStyleError {
  width: 100% !important;
  height: 5.1rem !important;
  font-size: 1.4rem !important;
  border: 0.15rem solid red !important;
  margin: 0.2rem 0 !important;
  outline: none !important;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
  color: #131313;
}
._3IfyxregionDDContainer,
._3Ifyxdanger {
  width: 100%;
  font-size: 1.4rem;
  border: 0.15rem solid #ebebeb !important;
  margin: 0.2rem 0;
  outline: none;
  font-family: "Source Sans Regular" !important;
  color: #131313;
  border-radius: 6px !important;
}
.danger {
  border: 1.5px solid red !important;
}

.datePicker {
  width: 100%;
  font-family: "Source Sans Regular" !important;
  font-size: 1.4rem;
  border: 1.5px solid #ebebeb !important;
  height: 4.8rem;
  border-radius: 4px;
}
.datePicker input {
  padding: 14px 14px;
  font-family: "Source Sans Regular" !important;
  font-size: 1.4rem !important;
}
/* .datePicker span {
  color: #131313 !important;
  padding: 0.2rem 0 !important;
  font-size: 1.5rem !important;
} */
.danger {
  border: 0.15rem solid red;
}
.info {
  border: 0.15rem solid #50a3b1;
  font-family: "Source Sans Regular";
}
.infoText {
  color: #50a3b1;
  font-size: 12px;
  margin-top: 5px;
  font-family: "Source Sans Regular";
}
.mailOtpContainer {
  padding: 0rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mailOtpContainer .input {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.mailOtpContainer button {
  color: #50a3b1;
  font-family: "Source Sans Regular";
  font-size: 1.2rem;
  width: 30%;
  padding-left: 1rem;
  border: none;
  border-left: 1.5px solid #ebebeb;
  background-color: transparent;
  outline: none;
  height: 60%;
  text-decoration: none;
}
.readOnlyMail {
  opacity: 0.5;
}
.disabledBtn {
  cursor: default;
}
.pwdField {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pwdField .pwdInput {
  width: 100%;
  display: block;
  padding: 1rem 1.5rem;
  padding-left: 0;
  border: none;
  font-size: 1.4rem;
  height: 4.3rem;
  border-radius: 4px;
  margin: 0.2rem 0;
  outline: none;
  font-family: "Source Sans Regular";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* hide select value */
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  /* color: transparent; */
  display: none;
}
.d_input_wrapper input {
  font-family: "Source Sans Regular";
  width: 100%;
  border: none;
  outline: none;
  padding: 0 1.5rem;
  height: 4.8rem;
  border: 0.13rem solid #ebebeb;
  font-size: 1.4rem;
  border-radius: 4px;
  background-color: transparent;
}
