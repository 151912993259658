.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.input_fields_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.input_fields_wrapper .fields {
  margin-right: 6rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_align_end {
  align-items: flex-end;
}

.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.popupHeading {
  margin-bottom: 0;
  font-size: 24px;
}

.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}

.VUpopupHeading {
  margin-bottom: 1.5rem;
  font-size: 24px;
}

.VUpopupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}

.users_heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;
}

.users_filters {
  align-items: flex-start;
}

.search_add_wrapper {
  margin: 0 1.5rem 1rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.d_dataTable,
.d_dataTable_sm {
  width: 98%;
  margin-left: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-20px);
}

.d_dataTable_sm {
  margin-top: 0;
}

.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 5rem;
  margin: 0 !important;
}

.InviteVendorPopup {
  margin: 0 !important;
}

.centerDiv {
  margin: 1rem 0;
}

.ViewVendorHeader,
.ViewVendorHeaderSM {
  width: 95%;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1.5px solid #97979770;
}

.tabsScreens {
  width: 98%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 2.5rem;
  padding-bottom: 3rem;
}

.infoSection {
  width: 100%;
  margin-bottom: 1rem;
}

.infoSection .info {
  width: 100%;
  /* display: grid;
    grid-template-columns: repeat(4, 22rem); */
  /* grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr)); */
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.infoSection .bidInfo {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 22rem);
  gap: 1rem;
}

.tabsScreens .tabHeading span {
  font-size: 16px;
  margin-bottom: 0.7rem;
  font-family: "gilroyBold";
}

.dateFilters {
  width: 190px;
  max-width: 200px;
  margin-bottom: 1rem;
}

.wrap {
  flex-wrap: wrap;
}

.compareSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rhsButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_container {
  width: 35rem;
  margin: 1rem;
  margin-right: 6rem;
}

.util {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radioTag {
  width: 8rem;
}

.bidType {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.bidType .bidStatus {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bidType .bidStatus .bidInputs {
  margin-right: 3rem;
  width: 24%;
}

.ViewVendorHeaderSM {
  width: 80%;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.actions {
  width: 82%;
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

p .customImageView {
  margin-left: 0;
  margin-bottom: 0;
}

p .customImageView .imgField {
  width: 16rem;
  height: 16rem;
  padding: 0;
}

.fullImageViewWrap {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  margin: auto;
  border-radius: 10px;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px !important;
  color: #131313;
  cursor: pointer !important;
}

.fullImageView {
  padding: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.desc {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

.d_dataTable_items {
  position: relative;
  width: 98%;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.clearBtn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 0 !important;
}

.filterBtnEl {
  background-color: #555555;
  min-width: 0 !important;
}

.filterBtnEl img {
  transform: rotate(134deg);
}

.selectFilterInput {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.searchFilters {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.searchFiltersWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.confimationPopup {
  height: 18rem;
  width: 53rem;
}

@media (max-width: 1600px) {
  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
}

@media (max-width: 1024px) {

  /* .users_tab_wrapper {
    width: 97%;
  } */
  .users_topHeader {
    margin: 0;
    margin-left: 1rem;
  }

  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }

  .d_dataTable {
    margin-left: 1rem;
  }

  .search_add_wrapper .rhsButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .d_dataTable_sm {
    margin-left: 1rem;
  }

  .tabsScreens {
    margin-left: 1rem;
  }

  .compareSection .flex {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .infoSection .info {
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  }

  .input_fields_wrapper,
  .input_fields_wrapper_multi_line1,
  .input_fields_wrapper_multi_line2 {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 40rem)) !important;
  }

  .items_form_wrapper {
    width: 100% !important;
  }

  .multiForm {
    width: 60rem !important;
  }

  .addNewForm {
    width: 100% !important;
  }

  .docField {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 40rem));
  }

  .ViewVendorHeaderSM {
    width: 98%;
  }

  .bidType {
    width: 98%;
  }

  .bidType .bidStatus {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 26rem));
  }

  .actions {
    width: 100%;
  }

  .infoSection .info {
    gap: 10px;
  }
}

@media (max-width: 800px) {

  .search_add_wrapper,
  .search_add_wrapper_content_left {
    flex-wrap: wrap;
  }

  .search_add_wrapper>.flex:first-child {
    justify-content: unset;
  }
}

@media (max-width: 600px) {
  .searchFiltersWrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .search_add_wrapper_content_left .flex {
    flex-wrap: wrap;
    justify-content: unset;
  }

  .dateFilters {
    width: 100%;
  }

  .d_dataTable {
    align-items: center;
  }

  .d_dataTable_sm {
    align-items: center;
  }
}