.superAdminContainer{
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 15px;
}
.users_tab_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.propmtItemDesc {
    display: flex;
    flex-direction: column;
    flex: 1 2 50%;
}
.users_topHeader {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.promptScreenMain{
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.promptScreen {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.propmtItem {
    width: 100%;
    padding: 2.4rem;
    background-color: #fff;
    border-radius: 0.6rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    -ms-border-radius: 0.6rem;
    -o-border-radius: 0.6rem;
    display: flex;
}
.promptTitle {
    font-size: 16px;
    line-height: 1.2;
    font-family: 'GilroyBold';
    margin-bottom: 12px;
}
.promptDescription {
    font-size: 16px;
    line-height: 1.2;
    font-family: 'GilroyMedium';
    flex: 1 auto;
}
.editPromptNotes {
    font-size: 1rem;
    line-height: 1.2;
    margin-left: 1rem;
    display: flex;
    position: relative;
    top: -3px;
}
.editPromptNotes > span {
    margin-left: .5rem;
    text-align: left;
}
.editBtnWrapper{
    flex: 1 1 150px
}
.edtBtn{
    margin: 0;
}

.nodataInforWrapper {

}
.nodataInforWrapper > p {
    font-size: 1.6rem;
    line-height: 1.2;
    font-family: 'GilroyMedium';
}
.updatePopup {

}
.promptSkeleton:first-child {
    margin-top: 3rem;
}
.promptSkeleton {
    width: 100%;
    margin-bottom: 5rem;
}
.promptModalFram {
    margin: 0 auto;
}
.successPopupTitle {
    margin-bottom: 2rem;
}
@media (max-width: 991px) {
    .editBtnWrapper {
        flex: 1 1 auto;
    }
    .promptScreenMain {
        margin-top: 0rem;
    }
}
@media (max-width: 550px) {
    .propmtItemDesc {
        margin-bottom: 30px;
    }
    .propmtItem{
        flex-direction: column;
        align-items: flex-start;
    }
    .editBtnWrapper{
        flex: 1 1 auto
    }
}