/* ===== compare table role based UI ===== */

.compareHeaderlogoWrapper {
  margin: 0;
  margin-right: 1rem;
}
.compareHeaderlogo {
  max-width: 120px;
}
.stickyHead {
  width: 200px;
  max-width: 200px;
}
.compareResultTable {
  width: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  overflow-x: scroll;
}
.above5CompareRecord {
  min-width: 130vw;
}
.compareResultTable body tr th {
  width: 200px;
}
th.defaultHead {
  background-color: #f5f2ff;
}
th.buyerHead {
  background-color: rgb(245, 242, 255);
  white-space: break-spaces;
  word-break: break-word;
  min-width: 200px;
}
th.vendorHead {
  background-color: var(--vendor-table--blue);
}
td.defaultHead {
  background-color: #f5f2ff;
}
td.buyerHead {
  background-color: rgb(245, 242, 255);
  white-space: break-spaces;
  word-break: break-word;
  min-width: 200px;
}
td.vendorHead {
  background-color: var(--vendor-table--blue);
}
.processedTable tr td {
  border-left: 1px solid #eee;
  word-break: break-all;
}

/* compare result table css */
.compairResultCard {
  padding: 1rem;
  width: 100%;
}
.compareResultCardHeaderWrapper {
  padding: 0rem 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
}
.compareResultCardHeaderWrapper.removePadding {
  padding: 0;
  background-color: transparent;
}
.compareResultCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.compairResultCard h4 {
  font-family: "gilroyMedium";
  font-size: 2rem;
  font-weight: normal;
}
.compairTable {
  margin-right: 3rem;
}
.container {
  position: relative;
  width: 100%;
}
.datatable_wrapper {
  width: 100%;
  margin: 0 0 1rem 0rem;
  overflow-x: auto;
  border-radius: 0.8rem;
  position: relative;
  overflow: scroll;
}
.mt30 {
  margin-top: 30px;
}
.compareTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
}
.comapreTitleCreatedHistory {
  display: flex;
  width: 100%;
}
.compareTitlePrnNo {
  width: 30%;
  font-size: 1.6rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.compareTitlePrnNo span {
  font-size: 1.4rem;
  color: #3a3a3c;
}
.compareTitleCompareOn {
  font-size: 1.6rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.compareTitleCompareOn span {
  font-size: 1.4rem;
  color: #3a3a3c;
}
.compareTitleDownloadIconWrapper {
}
.compareTitleDownloadPdfBtnWrapper {
  display: inline-flex;
}
.compareTitleDownloadPdfBtn {
  margin: 0;
}
/* Result file card css */
.comparedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  gap: 16px;
  margin-bottom: 2.8rem;
  margin-left: 16px;
}
.comparedFileItem {
  width: 100%;
  max-width: 22rem;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  padding: 22px 12px;
}
.comparedFileTitle {
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: "GilroyMedium";
  color: #3a3a3c;
  margin: 0;
  margin-bottom: 0.5rem;
}
/* btn css */
.viewDetailsBtn {
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-family: "gilroySemiBold";
  background-color: #fff;
  color: #3b86c7;
  border: none;
  display: inline-flex;
  align-items: center;
}
.btnIcon {
  height: 1.6rem;
  margin-left: 0.5rem;
}
.cancelBtn {
}
.copyJsonBtn {
}
.jsonViewBox {
  height: calc(100% - 63px);
}
.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jsonViewBox > ul {
  width: 100%;
  height: calc(100% - 42px);
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #f2f2f2 !important;
  padding: 4px !important;
  margin: 0;
  margin-top: 1rem !important;
}
.jsonTree {
}
.viewDetailsText {
  width: 100%;
  height: calc(100% - 42px);
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #f2f2f2 !important;
  padding: 14px !important;
  margin-top: 1rem;
}
.viewDetailsText > p {
  font-size: 14px;
  line-height: 1.5;
  font-family: "GilroyMedium";
}
.compareTableNoDataText {
  margin-top: 2rem;
}
.compareTableNoDataText > p {
  font-size: 2rem;
  font-family: "GilroyMedium";
  line-height: 1.5;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.accordianWraper::before {
  content: "";
  background-color: #fff !important;
  top: 0 !important;
}
.accordionSummaryWrapper[aria-expanded="true"] {
  min-height: 52px !important;
}
.accordionSummaryWrapper[aria-expanded="true"] div {
  margin: 0 !important;
}
.summaryContentExpand {
  margin: 14px 0 !important;
}
.noCompareDataAvailale {
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "GilroySemiBold";
  text-align: center;
}
@media (max-width: 1000px) {
  .compareTitlePrnNo {
    width: 40%;
  }
}
@media (max-width: 800px) {
  .compareResultCardHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .compairResultCard h4 {
    margin-top: 10px;
    font-size: 2rem;
  }
  .compareHeaderlogo {
    max-width: 100px;
  }
}
@media (max-width: 600px) {
  .compareTitle {
    flex-direction: column;
  }
  .compareTitleDownloadIconWrapper {
    width: 100%;
  }
  .compareTitleDownloadPdfBtnWrapper {
    margin-top: 10px;
  }
  .compareTitleDownloadPdfBtn {
    transform: scale(1);
    height: 3.7rem;
    padding: 0 12px;
  }
}
