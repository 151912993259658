.tab_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 20rem;
  cursor: pointer;
}
.tabDefault {
  position: relative;
  padding: 1.5rem 0;
  transform: translateY(3px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s;
}
.tabDefault:hover .tabText,
.tabDefault_sm:hover .tabText_sm {
  color: #000;
}
/* .tabDefault:hover::before,
.tabDefault_sm:hover::before {
  animation: hoverBg 0.15s both ease-in-out;
} */
/* @keyframes hoverBg {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
} */
/* .tabDefault::before,
.tabDefault_sm::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 100%;
  height: 40%;
  padding: 1rem;
  transform: scale(0);
  z-index: -1;
  border-radius: 6px;
  background-color: #adadad20;
} */
.tab_wrapper .tabActive {
  justify-content: flex-start;
  transform: translateY(3px);
  padding: 1.5rem 0;
  transition: all 0.2s;
  /* border-bottom: 5px solid #428ed0; */
  position: relative;
}
.tab_wrapper .tabActive::after,
.tab_wrapper_sm .tabActive_sm::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s;
  background-color: #428ed0;
  animation: borderBottom 0.15s both ease-in-out;
}
@keyframes borderBottom {
  0% {
    height: 1px;
  }
  100% {
    height: 5px;
  }
}
.tabText {
  font-family: "GilroyMedium";
  margin-left: 0;
  font-size: 18px;
  color: #3a3b3c50;
  transition: all 0.2s;
}
.tabTextActive {
  font-size: 18px;
  margin-left: 0;
  color: #3a3b3c;
  font-family: "GilroyMedium";
  transition: all 0.2s;
}
/* small */
.tab_wrapper_sm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 16rem;
  cursor: pointer;
}
.tabDefault_sm {
  padding: 1.5rem 0;
  transform: translateY(3px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s;
}
.tab_wrapper_sm .tabActive_sm {
  justify-content: flex-start;
  transform: translateY(3px);
  padding: 1.5rem 0;
  transition: all 0.2s;
}
.tabDefault_sm .tabText_sm {
  font-family: "GilroyMedium";
  margin-left: 0;
  font-size: 14px;
  transition: all 0.2s;
  color: #3a3b3c50;
}
.tabTextActive_sm {
  font-size: 14px;
  margin-left: 0;
  color: #3a3b3c;
  transition: all 0.2s;
  font-family: "GilroyMedium";
}
