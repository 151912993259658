.poPdfContainer {
  width: 100%;
  max-width: 650px;
  margin: auto;
  border: 1px solid #f2f2f2;
  position: absolute;
  top: -100%;
  left: -100%;
}
.issuerDetails {
}
.header {
  font-size: 14px;
  text-align: center;
  margin: 0; /* Ensure no extra margins */
}
.issuerDetailsTitle {
  font-size: 12px;
  text-align: center;
  padding-bottom: 5px;
}
.issuerDetailsAddress {
  text-align: center;
  font-size: 9px;
}
.issuerDetailsEmail {
  text-align: center;
  padding: 5px 0;
  font-size: 9px;
}
.issuerDetailsRegisterNumber {
  display: flex;
  justify-content: center;
  align-items: center;
}
.issuerDetailsRegisterNumber > li {
  display: inline-flex;
  margin: 0 10px;
  align-items: center;
}
.issuerDetailsRegisterNumber > li > h4 {
  padding-right: 5px;
  font-size: 10px;
}
.issuerDetailsRegisterNumber > li > p {
  font-size: 9px;
}
.orderNumberDate {
  display: flex;
  flex-direction: column;
}
.orderNumberDate > li {
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
  max-height: 30px;
}
.orderNumberDate > li > h4 {
  font-size: 10px;
  margin-right: 15px;
  min-width: 150px;
}
.orderNumberDate > li > p {
  font-size: 9px;
}
.detailsCard {
}
.detailsCard > h4 {
  font-size: 10px;
  padding-bottom: 5px;
}
.detailsCard > p {
  font-size: 9px;
}
.termsAndConditionContent {
}
.termsAndConditionContent > h4 {
  margin-bottom: 10px;
}
.termsAndConditionList {
  display: flex;
  flex-direction: column;
}
.termsAndConditionList > li {
  display: inline-flex;
  align-items: center;
  padding-bottom: 5px;
}
.termsAndConditionList > li > p {
  font-size: 9px;
  margin: 0;
  margin-right: 5px;
}
.termsAndConditionList > li > span {
  margin: 0;
  font-size: 9px;
}
.termsAndConditionList > p {
  font-size: 9px;
  margin: 0;
}
.customItemListTable {
}
.customItemListTable > thead > tr {
  height: 25px;
}
.customItemListTable > thead > tr > th {
  font-size: 9px;
}
.customItemListTable > thead > tr > th:nth-child(1) {
  width: 5%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(2) {
  width: 10%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(3) {
  width: 10%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(4) {
  width: 35%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(5) {
  width: 15%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(6) {
  width: 15%;
  text-align: center;
}
.customItemListTable > thead > tr > th:nth-child(7) {
  width: 10%;
  text-align: center;
}
.customItemListTable > tbody > tr > td {
  font-size: 9px;
}
.customItemListTable > tbody > tr > td:nth-child(1) {
  text-align: center;
}
.customItemListTable > tbody > tr > td:nth-child(2) {
  text-align: left;
}
.customItemListTable > tbody > tr > td:nth-child(3) {
  text-align: left;
}
.customItemListTable > tbody > tr > td:nth-child(4) {
  text-align: left;
}
.customItemListTable > tbody > tr > td:nth-child(5) {
  text-align: center;
}
.customItemListTable > tbody > tr > td:nth-child(6) {
  text-align: center;
}
.customItemListTable > tbody > tr > td:nth-child(7) {
  text-align: center;
}
.vendorDetailsTable {
}
.vendorDetailsTable > tbody > tr:first-child {
  height: 30px;
}
.verticalTop {
  vertical-align: baseline;
}
.cardTableHeader {
  font-size: 10px;
}
.vendorDetailsTable > tbody > tr > td:nth-child(1) {
  width: 30%;
}
.vendorDetailsTable > tbody > tr > td:nth-child(2) {
  width: 30%;
}
.vendorDetailsTable > tbody > tr > td:nth-child(3) {
  width: 30%;
}
.vendorDetailsTable p {
  margin: 0;
}
.termsContent {
}
.termsContent > p {
  margin: 0;
  font-size: 9px;
}
.termsContent li {
  font-size: 9px;
}
/* table style reset */
.table {
  width: 100%;
}
table.removeDefaultStyle {
  width: 100%;
}
table.removeDefaultStyle tbody {
}
table.removeDefaultStyle thead > tr,
table.removeDefaultStyle tbody > tr,
table.removeDefaultStyle tfoot > tr {
  border: none;
  height: 15px;
}
table.removeDefaultStyle thead > tr:hover,
table.removeDefaultStyle tbody > tr:hover,
table.removeDefaultStyle tfoot > tr:hover {
  background-color: transparent;
}
table.removeDefaultStyle thead > tr > th,
table.removeDefaultStyle tbody > tr > td,
table.removeDefaultStyle tfoot > tr > td {
  padding: 0;
}
table.borderColapse thead > tr > th,
table.borderColapse tbody > tr > td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
table.removeBorder thead > tr > th,
table.removeBorder tbody > tr > td {
  border: none;
  border-collapse: collapse;
  padding: 5px;
  border-bottom: 1px solid #343434;
  border-right: 1px solid #343434;
}
