.select_container,
.d_input_container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 1rem;
  margin-bottom: 1.5rem;
}

.phoneInputStyle {
  width: 100% !important;
  font-size: 1.4rem !important;
  /* border: 0.15rem solid #ebebeb !important; */
  margin: 0.2rem 0 !important;
  padding-left: 4rem !important;
  outline: none !important;
  color: #131313;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
}

.phoneInputContainerStyle {
  width: 100% !important;
  font-size: 1.4rem !important;
  /* margin: 0.2rem 0 !important; */
  outline: none !important;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
  color: #131313;
}

.phoneInputStyleError {
  width: 100% !important;
  font-size: 1.4rem !important;
  border: 0.13rem solid red !important;
  /* margin: 0.2rem 0 !important; */
  outline: none !important;
  font-family: "Source Sans Regular" !important;
  border-radius: 6px !important;
  color: #131313;
}

.select_container label,
.d_input_container label {
  font-size: 1.4rem;
  color: #a4a4a4;
  font-family: "GilroyMedium";
  margin: 0 0 0.6rem 0;
}

.show_count {
  font-size: 10px;
  font-family: "gilroyBold";
}

.select_container .select_wrapper,
.select_container .select_wrapper_multiSelect,
.d_input_container .d_datepicker_wrapper,
.d_input_container .d_datepicker_wrapper .d_datepicker,
.d_input_container .d_input_wrapper {
  width: 100%;
  border: 0.13rem solid #97979770;
  background-color: transparent;
  border-radius: 3px;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 0.6rem;
}

.d_input_container .d_datepicker_wrapper .d_datepicker {
  border: 0;
}

.select_container .select_wrapper {
  /* border: 0.15rem solid red; */
  border: 0.13rem solid #97979770;
  transition: none;
  height: 4.5rem;
}

.select_container .select_wrapper_multiSelect {
  /* border: 0.15rem solid red; */
  border: 0.13rem solid #97979770;
  transition: none;
  min-height: 4.5rem;
  height: 100%;
}

.d_input_container .d_datepicker_wrapper {
  border: 0.13rem solid #97979770;
}

.d_pwd_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d_textarea_container {
  height: 144px;
  min-height: 144px;
  max-height: 144px;
}

.d_input_container .textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "GilroyMedium";
  font-size: 14px;
  background-color: transparent;
  border-radius: 0.6rem;
  padding: 1rem;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.select_container select,
.d_input_container .d_input_wrapper input {
  font-family: "GilroyMedium";
  width: 100%;
  border: none;
  outline: none;
  padding: 0 1.5rem;
  height: 4.2rem;
  font-size: 1.4rem;
  background-color: transparent;
}

.error_message {
  color: red;
  font-size: 1.2rem;
  margin: 0.3rem 0;
  font-family: "GilroyMedium";
  text-align: right;
}

.info {
  color: #adadaf;
  width: max-content;
  text-align: left;
  font-size: 1.2rem;
  margin: 0.8rem 0;
  font-family: "GilroyMedium";
}

.d_input_wrapper.error,
.d_datepicker_wrapper.error {
  border: 0.15rem solid red;
}

.select_wrapper_multiSelect.error {
  border: 0.15rem solid red;
}

.select_wrapper.error {
  height: 4.7rem;
  border: 0.15rem solid red;
}

.password_toggle_button {
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 1.5rem;
}

.password_toggle_button div {
  margin: 0;
}

.disabledField {
  opacity: 0.7;
}

/* profile picture upload */
.upload_container,
.upload_container_sm {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Regular";
  margin-bottom: 1rem;
  border: 1px solid #55555520;
}

.upload_container_sm {
  width: 91px;
  height: 91px;
}

.upload_wrapper,
.upload_wrapper_sm {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_wrapper_sm {
  width: 91px;
  height: 91px;
}

.upload_wrapper .upload_input {
  visibility: hidden;
  display: none;
}

.upload_label,
.upload_label_sm {
  z-index: 9;
  position: absolute;
  bottom: 0;
  right: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0.7rem;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.upload_icon_sm,
.upload_icon {
  width: 2rem;
  height: 2rem;
  margin: 0;
}

.upload_label_sm {
  padding: 0.5rem;
  right: 24px;
}

.upload_icon_sm {
  width: 1.7rem;
  height: 1.7rem;
}

.uploaded_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.uploaded_image_frame,
.uploaded_image_frame_sm {
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
}

.uploaded_image_frame_sm {
  width: 81px;
  height: 81px;
}

.cancel_button,
.cancel_button_sm {
  position: absolute;
  z-index: 9;
  top: 10px;
  right: 13px;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  padding: 0.7rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.cancel_button_sm {
  padding: 0.4rem;
  top: 0;
  right: 23px;
}

.cancel_button_sm .cancel_icon_sm,
.cancel_button .cancel_icon {
  z-index: 9;
  width: 2rem;
  height: 2rem;
  margin: 0;
}

.cancel_icon_sm,
.upload_icon_sm {
  width: 1.7rem;
  height: 1.7rem;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-right: 1.5rem;
  font-family: "Source Sans Regular";
}

.listElement {
  font-family: "gilroyMedium";
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

.listElement:hover {
  background: #eee;
}

/* default dropdown styles */
.select {
  padding: 0;
  border-radius: 6px !important;
  border: 0.13rem solid #97979770;
}

.error {
  border: 0.13rem solid red;
}
.infoMessageWrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.infoMessage {
  font-size: 14px;
  line-height: 1.5;
  margin: 0px;
  margin-left: 5px;
  color: #5b5353;
}
