.ql-toolbar.ql-snow + .ql-container.ql-snow {
  max-height: 400px;
  overflow: scroll;
}
strong {
  font-weight: 600;
}
em {
  font-style: italic;
}
