.ErrorElement {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ErrorElement img {
  border-radius: 10px;
  width: 15rem;
  height: 15rem;
  margin-bottom: 1.5rem;
}
.ErrorElement p {
  margin: 0;
  font-size: 1.6rem;
  color: #adadad;
  font-family: "gilroyRegular";
}
.ErrorElement span {
  margin: 0;
  font-family: "gilroySemiBold";
  font-size: 2.5rem;
}
