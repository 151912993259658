.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_tab_wrapper {
  width: 100%;
  /* max-width: 1600px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 1.5rem;
  width: 100%;
  margin-top: 1rem;
}

.filters {
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.filterBtn {
  margin-top: 2rem;
}

.filterBtn .button {
  margin-bottom: 1rem;
}

.filters .filterFields {
  width: 190px;
  max-width: 200px;
  margin: 0 1rem;
  margin-bottom: 1rem;
}

/* //content */
.mainContent {
  width: 98%;
  margin: 1rem 2.5rem;
}

.reports {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  gap: 0 2.5rem;
}

.metrics {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.metrics .topFour {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(475px, 1fr));
  gap: 0 2.5rem;
}

.monthPicker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.monthPicker span {
  font-family: "gilroyBold";
  font-size: 12px;
  margin-right: 1rem;
}

.duration {
  width: 89px;
  height: 10px;
}

.linearProgressInline {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.barsCard {
  max-height: fit-content;
  align-items: flex-start;
  flex-direction: column;
}

.filterDropdown {
  height: 2.7rem;
  width: 8.9rem;
  border-radius: 6px;
  border: 1.5px solid #eee;
  font-family: "gilroyMedium";
  font-size: 1rem;
  text-align: center;
  color: #000;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  outline: none;
}

.filterDropdown::-ms-expand {
  display: none !important;
}

.chartfirstRow {
  /* height: 245px; */
}

.ChartSecondRow {
  justify-content: flex-start !important;
}

.chartThirdRow {
  height: 323px;
  max-width: 100%;
  width: 100% !important;
}

.chartFourthRow {
  height: 402px;
  max-width: 100%;
  width: 100% !important;
}

.filterSearch {
  display: flex;
  align-items: flex-start;
}

/* responsive */
@media (min-width: 1700px) {
  .metrics {
    justify-content: flex-start;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .dashboard_tab_wrapper {
    width: 100%;
  }

  .mainContent {
    width: 100%;
  }

  .topHeader,
  .heading_inputs,
  .mainContent,
  .reports {
    padding: 0 1rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  .reports,
  .metrics {
    padding: 0 2rem;
  }

  .mainContent {
    padding: 0;
  }
}

@media (max-width: 580px) {
  .filters .filterFields {
    width: 44%;
  }

  .filterSearch {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .metrics .topFour {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .filters {
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  .filters .filterFields {
    width: 42%;
  }
}
