.notification_wrapper {
  position: relative;
}
.notification_icon {
  margin: 1rem;
}
.notification_icon img {
  cursor: pointer;
  width: 4.8rem;
  height: 4.7rem;
  border: 1px solid #00000006;
  border-radius: 50%;
  transform: scale(1.09) translateY(2px);
}
.notification_panel {
  position: relative;
  width: 40rem;
  border-radius: 1.1rem;
  background-color: var(--bg-light);
}
.notificationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #adadad30;
  padding: 1rem 2rem;
}
.notification_panel .notificationHeader span {
  font-size: 1.6rem;
  font-family: "gilroySemiBold";
}
.notificationCard {
  font-size: 1.3rem;
  padding: 1rem 2rem;
  font-family: "gilroyMedium";
  margin: 0;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #fff;
  border-bottom: 1px solid #adadad60;
}
.notificationCardFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notificationCard p {
  margin-bottom: 1rem;
}
.notificationCard .createdDate {
  font-size: 1.2rem;
  font-family: "gilroySemiBold";
  background-color: #ffffff90;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  border: 1px solid #efefef;
  color: #555;
}

.notificationCard.unread {
  background-color: #06436b26;
}
.notificationCard:hover {
  transition: all 0.3s;
  background-color: #06436b26;
}
.notification_indicator {
  position: absolute;
  top: 1rem;
  border: 0.2rem solid #fff;
  left: 4.4rem;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ff3b05;
  border-radius: 50%;
}
.closeBtn {
  border-radius: 50%;
  transition: all 0.3s;
}
.closeBtn:hover {
  transition: all 0.3s;
  background-color: #06436b26;
}
.filterDropdown {
  height: 3.2rem;
  width: 6rem;
  border-radius: 6px;
  border: 1.5px solid #eee;
  font-family: "gilroyMedium";
  font-size: 1.1rem;
  margin: 0 1rem;
  text-align: center;
  color: #000;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  outline: none;
}
.filterDropdown::-ms-expand {
  display: none !important;
}
.filterOptions {
  position: sticky;
  left: 0;
  top: 5.06rem;
  height: 50px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02);
}
.markAsAllRead {
  margin: 0 1rem;
  border: none;
  outline: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  width: 13rem;
  font-family: "gilroyMedium";
  border-radius: 4px;
  transition: all 0.3s;
  background-color: #06436b;
  color: #fff;
}
.markAsAllRead:hover {
  transition: all 0.3s;
  background-color: #06436be2;
  color: #fff;
}
.notificationError {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0;
}
.notificationError p {
  font-size: 1.4rem;
  color: #adadad90;
  font-family: "gilroySemiBold";
  user-select: none;
}

@media (max-width: 768px) {
  .notification_icon img {
    width: 4rem;
    height: 4rem;
  }
}
@media (max-width: 400px) {
  .notification_panel {
    width: 30rem;
  }
}
@media (max-width: 300px) {
  .notification_panel {
    width: 100%;
  }
}
