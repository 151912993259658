.comparison_tab_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.fields {
  margin-right: 6rem;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}
.popupHeading {
  margin-bottom: 0;
  font-size: 24px;
}
.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.VUpopupHeading {
  margin-bottom: 1.5rem;
  font-size: 24px;
}
.VUpopupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.users_heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;
}

.users_filters {
  align-items: flex-start;
}
.search_add_wrapper {
  margin: 0 1.5rem 1rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d_dataTable,
.d_dataTable_sm {
  width: 98%;
  margin-left: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-20px);
}
.d_dataTable_sm {
  margin-top: 0;
}
.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 5rem;
  margin: 0 !important;
}
.InviteVendorPopup {
  margin: 0 !important;
}
.centerDiv {
  margin: 1rem 0;
}
.ViewVendorHeader,
.ViewVendorHeaderSM {
  width: 100%;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1.5px solid #97979770;
}
.tabsScreens {
  width: 98%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 2.5rem;
  padding-bottom: 3rem;
}
.infoSection {
  width: 100%;
  margin-bottom: 1rem;
}
.infoSection .info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 22rem);
  gap: 10px;
}
.info.compareSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabsScreens .tabHeading span {
  font-size: 16px;
  margin-bottom: 0.7rem;
  font-family: "gilroyBold";
}
.wrap {
  flex-wrap: wrap;
}
.info.compareSection {
  justify-content: space-between;
}
.rhsButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  margin-right: 6rem;
}
.util {
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bidType {
  width: 86rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.bidType .bidStatus {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 26rem);
  gap: 0 4rem;
}
.bidType .bidStatus .bidInputs {
  width: 24%;
}
.ViewVendorHeaderSM {
  width: 86rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.actions {
  width: 88rem;
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.docField {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 40rem);
  gap: 0 7rem;
}
.proposal_desc {
  margin: 0;
}
.unitsInputs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.uniqueSelect {
  width: 100%;
  margin: 0;
}
p .customImageView {
  margin-left: 0;
  margin-bottom: 0;
}
p .customImageView .imgField {
  width: 16rem;
  height: 16rem;
  padding: 0;
}
.confimationPopup {
  height: 18rem;
  width: 53rem;
}
.infoText {
  font-size: 2rem;
  text-align: center;
  color: #adadad;
  font-family: "gilroyMedium";
}
.formErrorWrap {
  width: 100%;
  margin: 2rem;
  height: 60%;
}
.desc {
  width: 100%;
  overflow: auto;
  max-height: 400px;
}
.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100px);
}
.uploadSection {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  /* margin-top: 3rem; */
}
.uploadSection .uploadInput:nth-child(2) {
  margin-top: 30px;
}
.uploadSection .uploadInput {
  width: 400px;
  margin-right: 10%;
}
.fileHeading {
  font-family: "gilroyMedium";
  font-size: 2.4rem;
  margin-left: 1rem;
}
/* comparehistory compare card css*/
.HistorySkeleton {
  width: 25%;
}
.historyInputWrapper {
  margin: 0;
}
.comapreBtn {
  margin: 0;
}
.compareHistory {
  width: calc(100% - 2.5rem);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2.5rem;
  margin-bottom: 3rem;
}
.searchHistory {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}
.cardMain {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: 100%;
}
.cardItem {
  width: 100%;
  max-width: calc(25% - 1.5rem);
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
}
.cardHeader {
}
.cardHeader > h4 {
  font-size: 1.6rem;
  font-family: "GilroySemiBold";
  margin-bottom: 1.5rem;
}
.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.comparedDateWrapper {
}
.comparedDateTitle {
  font-size: 1.4rem;
  font-family: "GilroyMedium";
  color: #adadad;
  margin: 0;
  margin-bottom: 0.5rem;
}
.comparedDate {
  font-size: 1.4rem;
  font-family: "GilroyMedium";
  line-height: 1.8rem;
  margin: 0;
}
.compareViewBtn {
  font-size: 1.4rem;
  font-family: "GilroySemiBold";
  border: none;
  background-color: #fff;
  color: var(--fp-link);
}
.paginationWrapper {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
/* compare result table css */
.compairResultCard {
  padding: 1rem;
  width: 100%;
}
.compareResultCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.compairResultCard p {
  font-family: "gilroyMedium";
  font-size: 2.4rem;
}
.compairTable {
  margin-right: 3rem;
}
.container {
  position: relative;
  width: 100%;
}
.datatable_wrapper {
  width: 100%;
  margin: 0 0 1rem 0rem;
  overflow-x: auto;
  border-radius: 0.8rem;
  position: relative;
}
.comparedTableBack {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/* ----------- multiple file upload UI ----------- */
.fileUploadWrapperOuterMain {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.fileUploadWrapperMain {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.removeUploadButton {
  background-color: #fff;
  color: var(--login-text-danger);
  font-size: 13px;
  margin-right: 0.5rem;
  border: 0;
  border-radius: 4px;
  font-family: "gilroySemiBold";
  outline: 0;
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
  cursor: pointer;
}
.addUploadButton {
  border: 0;
  border-radius: 4px;
  font-family: "gilroySemiBold";
  outline: 0;
  padding: 0.8rem 2rem;
  background-color: #075b9710;
  color: var(--fp-link);
  font-size: 14px;
  margin-left: auto;
}
.addUploadButton:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.compareModalImgWrapper {
  width: 15%;
  margin-bottom: 1rem;
}
.compareModal p {
  max-width: 100%;
}
.positionTopRight {
  position: absolute;
  right: 10px;
  top: 10px;
}
.historyNotAvailable {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  height: 30px;
  display: block;
  align-self: center;
}
.historyNotAvailable > h2 {
  font-size: 2rem;
  font-family: "gilroyBold";
}

.compareBtnWrapper {
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
}
.compareBtn {
}
.compareBtnWrapper > p {
  max-width: 100%;
  padding-left: 10px;
  margin-top: 5px;
}

/* ----------- multiple file upload UI ----------- */

@media (max-width: 1500px) {
  .cardItem {
    max-width: calc(33.333% - 1.5rem);
  }
}
@media (max-width: 1200px) {
  .cardItem {
    max-width: calc(50% - 1.5rem);
  }
}
@media (max-width: 1024px) {
  /* .comparison_tab_wrapper {
    width: 97%;
  } */

  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
  .d_dataTable {
    margin-left: 1rem;
  }
  .search_add_wrapper .rhsButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .d_dataTable_sm {
    margin-left: 1rem;
  }
  .tabsScreens {
    margin-left: 1rem;
  }
  .addPopup {
    margin: 150px 0 !important;
  }
  .users_topHeader {
    margin-left: 1rem;
  }
}
@media (max-width: 600px) {
  .uploadInput {
    width: 90%;
  }
  .tabsScreens .fields {
    width: 90% !important;
  }
  .positionTopRight {
    position: relative;
    top: 0;
    right: 5px;
  }
  .searchHistory {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .comapreBtn {
    height: 3.6rem !important;
    justify-content: center;
    margin-bottom: 2rem;
    padding: 0 1.5rem;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  .cardItem {
    max-width: calc(100% - 1.5rem);
  }
}
