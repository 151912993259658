.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}
.rfqDraftSection {
}
.rfqDrafSection {
  display: flex;
  width: 100%;
}
.poListRow {
  width: 100%;
}
.search_add_wrapper {
  margin: 0 1.5rem 1rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.d_dataTable,
.d_dataTable_sm {
  width: 98%;
  margin-left: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-20px);
}

.d_dataTable_items {
  position: relative;
  width: 98%;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.d_dataTable_sm {
  margin-top: 0;
}
/* crate rfq css */
.mp100vh {
  /* margin-top: 100vh; */
  left: -100%;
  position: absolute;
  top: 0;
}
.downloadreportViewMain {
  left: -100%;
  position: absolute;
  top: 0;
}
.downloadreportViewMain.show {
  position: relative;
  left: 0;
}
.mb20 {
  margin-bottom: 20px;
}
.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.items_form_wrapper {
  width: 88rem;
  padding: 1.5rem;
  border-radius: 6px;
  background-color: #eeeeee40;
  margin-bottom: 1rem;
  /* margin-left: 1rem; */
}

.statusSelect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemCount {
  padding: 0.5rem 2rem;
  margin: 0;
  width: max-content;
  border-radius: 4px;
  background-color: #eeeeee70;
  font-size: 1.2rem;
  font-family: "gilroySemiBold";
  margin: 0 0 1rem 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
}

.multiForm {
  width: 95rem;
  max-height: 500px;
  overflow-y: auto;
}

.input_fields_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 40rem);
  gap: 0 6.2rem;
}

.items_form_wrapper .input_fields_wrapper_multi_line1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 39.5rem);
  gap: 0 2rem;
}

.input_fields_wrapper_multi_line2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 25.7rem);
  gap: 0 2rem;
}

.input_fields_wrapper .fields {
  margin-right: 6rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_align_end {
  align-items: flex-end;
}

.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.popupHeading {
  margin-bottom: 0;
  font-size: 24px;
}

.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}

.heading_pop_pup {
  font-size: 20px;
  margin: 0;
}

.error_msg {
  color: red;
  font-size: 12px;
  text-align: start;
  margin-top: 1rem;
}

.flex_end {
  justify-content: end;
}

.width__100 {
  width: 100%;
}

.view__time_line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 7px;
}

.view__time_line_titel {
  margin: 0.8rem 1rem;
  font-size: 16px;
  font-family: "GilroyMedium";
}

.padding_b_1rem {
  padding-bottom: 1rem;
}

.time_line_box {
  margin: 0.8rem 1rem;
}

.padding_box {
  margin: 0.8rem 1rem;
  margin-left: 0;
}

.left_titel {
  font-size: 13px;
  font-family: "GilroyMedium";
  margin-left: 0px;
  word-break: break-word;
}

.right_progrop {
  font-size: 13px;
  color: #727272;
  font-family: "GilroyMedium";
  margin: 0 0 0.6rem 0;
  width: 90%;
}

.rfq_time_line_contant {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.right_progrop_date_time {
  font-size: 13px;
  color: #9c9c9c;
  font-family: "GilroyMedium";
  margin: 0 0 0.6rem 0;
}

.overflow_hadle {
  max-height: 57rem;
  overflow-y: scroll;
}

.box_loyout_left {
  border-right: 1px solid #eaeaea;
}

.time_line_box_loyout {
  display: grid;
  gap: 10px;
  grid-template-columns: 100px 1fr;
}

.bgcolor {
  background-color: #3b86c7;
  color: #fff;
  border: none;
  outline: none;
}

.heading_sub_text {
  font-size: 15px;
  color: #525252ac;
  margin: 0;
  margin-bottom: 1.5rem;
  text-align: start;
}

.VUpopupHeading {
  margin-bottom: 1.5rem;
  font-size: 24px;
}

.VUpopupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}

.users_heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;
}

.users_filters {
  align-items: flex-start;
}

.search_add_wrapper {
  margin: 0 1.5rem 1rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.d_dataTable,
.d_dataTable_sm {
  width: 98%;
  margin-left: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-20px);
}

.d_dataTable_items {
  position: relative;
  width: 98%;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.d_dataTable_sm {
  margin-top: 0;
}

.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 5rem;
  margin: 0 !important;
}

.InviteVendorPopup {
  margin: 0 !important;
}

.centerDiv {
  margin: 1rem 0;
}

.ViewVendorHeader,
.ViewVendorHeaderSM {
  width: 100%;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1.5px solid #97979770;
}

.tabsScreens {
  width: 98%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 2.5rem;
  padding-bottom: 3rem;
}

.dateFilters {
  width: 190px;
  max-width: 200px;
  margin-bottom: 1rem;
}

.infoSection {
  width: 100%;
  margin-bottom: 1rem;
}

.infoSection .info {
  width: 100%;
  /* display: grid;
    grid-template-columns: repeat(4, 22rem); */
  /* grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr)); */
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.info.compareSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tabsScreens .tabHeading span {
  font-size: 16px;
  margin-bottom: 0.7rem;
  font-family: "gilroyBold";
}

.wrap {
  flex-wrap: wrap;
}

.info.compareSection {
  justify-content: space-between;
}

.rhsButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  margin-right: 6rem;
}

.util {
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radioTag {
  width: auto;
}

.bidType {
  width: 86rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.bidType .bidStatus {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 26rem);
  gap: 0 4rem;
}

.bidType .bidStatus .bidInputs {
  width: 24%;
}

.ViewVendorHeaderSM {
  width: 86rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.actions {
  width: 88rem;
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.docField {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 7rem;
}

.proposal_desc {
  margin: 0;
}

.unitsInputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-direction: column; */
}

.uniqueSelect {
  width: 100%;
  margin: 0;
}

p .customImageView {
  margin-left: 0;
  margin-bottom: 0;
}

p .customImageView .imgField {
  width: 16rem;
  height: 16rem;
  padding: 0;
}

.confimationPopup {
  height: 18rem;
  width: 53rem;
}

.infoText {
  font-size: 2rem;
  text-align: center;
  color: #adadad;
  font-family: "gilroyMedium";
}

.formErrorWrap {
  width: 100%;
  margin: 2rem;
  height: 60%;
}

.desc {
  width: 100%;
  overflow: auto;
  max-height: 400px;
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100px);
}

.headingSm {
  font-size: 16px;
  margin: 1rem;
  color: #aaa4a4;
  font-family: "gilroyMedium";
}

.itemsListWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.compareModal p {
  max-width: 46rem;
}

.chatActionBntWrapper {
}
.chatBtn {
  display: inline-block;
}
.fixedHeader {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}
.fixedHeader > table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.addNewForm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 87rem;
  margin-left: -1rem;
}

.addNewFormButton {
  margin-left: auto;
  background-color: #075b9710;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  border: 0;
  outline: 0;
  color: var(--fp-link);
  font-size: 14px;
  font-family: "gilroySemiBold";
}

.removeFormButton {
  margin-left: auto;
  background-color: #ff000010;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  border: 0;
  outline: 0;
  color: var(--login-text-danger);
  font-size: 13px;
  font-family: "gilroySemiBold";
  margin-left: 1rem;
  margin-top: 1rem;
}

.quotationWrapper,
.comparisonTableContainer {
  position: relative;
  width: 97%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  background-color: #fff;
  border-radius: 6px;
}

.quotationWrapper span,
.comparisonTableContainer span {
  font-size: 20px;
  font-family: "gilroySemiBold";
}

.pdfWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem;
}

.vendorDocumentsWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 2rem;
}

.pdfcodeviewr {
  min-width: 624px;
  border: 1px solid #a5a5a5;
}

.pdfWrapper .buyerPDFHeading {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  /* margin: 1rem; */
  margin-left: 0;
  background-color: #eeeeee;
  padding: 0.8rem 1rem;
  border-radius: 4px;
}

.vendorDocumentsWrapper .vendorName {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  /* margin: 1rem; */
  margin-left: 0;
  background-color: #eeeeee;
  padding: 0.8rem 1rem;
  border-radius: 4px;
}

.vendorDocumentsViewer {
  height: 100%;
  width: 100%;
  margin: auto;
}

/* .vendorDocumentsViewer:first-child {
    padding-bottom: 6rem;
  } */

.vendorDocumentsViewer + .vendorDocumentsViewer {
  /* padding: 6rem 0; */
  border-top: 1px solid var(--dashboard-gray);
}

.pdfWrapper .buyerPDFHeading {
  display: inherit;
  width: 100%;
  /* margin: 2rem; */
  margin-left: 0;
}

.buyerReqPDF {
  margin-top: 1rem;
}

.buyerPDFView {
  width: 50%;
  height: 80vh;
  overflow: auto;
  padding: 0px 14px;
}

.vendorPDFView {
  width: 50%;
  /* margin: 1rem; */
  height: 80vh;
  overflow: auto;
  padding: 0px 15px;
  margin: auto;
}

/* .vendorPDFView::-webkit-scrollbar {
    display: none;
  } */

.pdfView {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #adadad;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.comparisonTableContainer {
  height: 95vh;
  border-radius: 6px;
  justify-content: flex-start;
}

.comparisonTableContainer .comparisonTableWrapper {
  width: 98%;
  margin: 2rem;
  padding: 0;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0.8rem;
}

.comparisonTableContainer .comparisonTableWrapper table {
  width: 100vw;
  height: auto;
  padding: 0;
}

.comparisonTableContainer .comparisonTableWrapper table thead {
  background-color: var(--buyerSideBar-bg-light-blue);
  z-index: 99;
  position: sticky;
  top: 0;
  left: 0;
}

.comparisonTableContainer .comparisonTableWrapper table tbody tr td {
  border-right: 1px solid #eee;
}

.comparisonTableContainer .comparisonTableWrapper table thead tr th {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  min-width: 300px;
  max-width: 600px;
}

.comparisonTableContainer
  .comparisonTableWrapper
  table
  thead
  tr
  th:first-child {
  border-top-left-radius: 6px;
}

.comparisonTableContainer .comparisonTableWrapper table thead tr th:last-child {
  border-top-right-radius: 6px;
}

.comparisonTableContainer .comparisonTableWrapper table tr td:nth-child(1) {
  background-color: var(--buyerSideBar-bg-light-blue);
}

.linkBtn {
  color: #0000ffac;
  font-size: 1.4rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: "GilroyMedium";
  text-decoration: underline;
}

.stickyCol {
  position: sticky;
  left: 0;
  top: 0;
}

.itemDescription {
  height: 12rem;
  margin: 10px;
}

.itemDescriptionTextArea {
  min-height: auto !important;
  max-height: auto !important;
  height: 8rem !important;
}

.addPopup {
  margin: 0 !important;
}

.centerDiv {
  margin: 1rem 0;
}

.textAreaWrapper {
  min-height: auto !important;
  max-height: auto !important;
  height: auto !important;
}

@media (max-width: 1400px) {
  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .d_dataTable {
    margin-left: 1rem;
  }

  .d_dataTable_sm {
    margin-left: 1rem;
  }
  .search_add_wrapper .rhsButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (max-width: 500px) {
  .d_dataTable {
    align-items: center;
  }

  .d_dataTable_sm {
    align-items: center;
  }
}
