.cus_radio {
  position: relative;
  margin-right: 3.5rem;
}

.cus_radio label {
  position: relative;
  font-size: 15px;
}

/* Radio button styles */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  /* left: -9999px; */
  display: none;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 14px;
  color: #000;
  font-family: "GilroyMedium";
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #000;
  border-radius: 100%;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
