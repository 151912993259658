.flex {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.container {
  position: relative;
  width: 100%;
}

/* Styles for the table */
.datatable_wrapper {
  width: 100%;
  margin: 0 0 1rem 0rem;
  overflow-x: auto;
  border-radius: 0.8rem;
  position: relative;
}

.datatable_wrapper table {
  background-color: var(--bg-light);
  border-collapse: collapse;
}

.infoMsg {
  font: 1.4rem/2rem "GilroyMedium";
  color: #adadad;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50px);
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
}

th,
td {
  word-break: keep-all;
  padding: 1.3rem 1.8rem 1.1rem 1.8rem;
  text-align: left;
  font: 1.4rem/2rem "GilroyMedium";
}

th {
  font-family: "GilroySemiBold";
  text-align: left;
}

thead tr {
  height: 60px;
}

tbody tr {
  border-bottom: 1.3px solid var(--dsb-table-border-gray);
  height: 60px;
}

tbody tr:hover {
  background-color: #97979712;
}

td .actBtn {
  transform: translate(0, 0.8rem);
  width: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.activeRow {
  background-color: #abcafc26;
}

/* Styles for the header */
th.defaultHead {
  background-color: #f5f2ff;
}

.timeicon {
  width: 2rem;
  margin: 0;
  cursor: pointer;
}

th.buyerHead {
  background-color: var(--buyerSideBar-bg-light-blue);
  white-space: pre;
}

th.vendorHead {
  background-color: var(--vendor-table--blue);
}

th .sortIcon {
  width: 1.3rem;
  height: 1.3rem;
  vertical-align: middle;
  margin: 0 0 0.2rem 0.5rem;
  cursor: pointer;
}

/* Styles for the checkboxes */
/* th:first-child,
td:first-child {
  width: 6rem;
  text-align: center;
} */

.datatable_wrapper table thead tr th:first-child,
.datatable_wrapper table tbody tr td:first-child {
  padding-left: 4rem;
}

/* Styles for the sorting icons */
th > button,
td > button {
  padding: 0;
  background-color: transparent;
  border: none;
}

td.action {
  display: flex;
  align-items: center;
  justify-content: center;
}

td.action div {
  margin: 0;
}

.action .actBtn {
  transform: translate(0, 0.8rem);
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.profile_image {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: middle;
  margin-right: 1.3rem;
  border-radius: 50%;
}

.prName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.prName span {
  vertical-align: middle;
}

.activeStatus {
  font-size: 1.2rem;
  font-family: "gilroySemiBold";
  text-align: center;
  background-color: #cdffef;
  color: #2a9a76;
  display: block;
  border-radius: 20px;
  padding: 1rem;
  height: 30px;
  line-height: 11px;
  text-transform: capitalize;
}

.InActiveStatus {
  color: #c72b18;
  font-size: 1.2rem;
  font-family: "gilroySemiBold";
  text-align: center;
  background-color: #f9d5d0;
  display: block;
  border-radius: 20px;
  padding: 1rem;
  height: 30px;
  line-height: 11px;
  text-transform: capitalize;
}

.selectBidBtn {
  display: inline;
}

.unSelectBidBtn {
  display: flex;
}

.selectBidBtn button span {
  font-size: 13px;
}

.unSelectBidBtn button span {
  font-size: 13px;
}

.bidSelectedText {
  font-size: 13px;
  text-align: center;
  color: #378827;
  font-family: "gilroySemiBold";
  width: 12rem;
}

.SelectBidSection {
  justify-content: flex-end;
}

.ownBid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ownBidImage,
.fileBtn {
  width: 2rem;
  margin-left: 0.5rem;
  margin-right: 0rem;
}

.fileBtn {
  cursor: pointer;
}

.headingCenter {
  text-align: center;
}
.marginAuto {
  margin: auto;
}
.bidDescription {
  margin: 1rem;
  max-height: 30rem;
  overflow-y: auto;
}

.bidDescription p {
  font-family: "gilroyMedium";
  font-size: 1.4rem;
}

.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem;
  margin: 0 !important;
}

.viewDescriptionBtn {
  cursor: pointer;
  text-decoration: underline;
}

.viewDescriptionBtn:hover {
  color: #0000ffac;
}

.successBid {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.bidSuccessImg {
  margin-right: 1rem;
}

.disabled {
  opacity: 0.3;
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.datatable_wrapper .stickyHead {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
}

/* responsive */
/* @media (min-width: 1350px) {
  table {
    width: 90vw !important;
  }
} */

@media (min-width: 2000px) {
  table {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  table {
    width: 150vw !important;
  }

  /* .datatable_wrapper {
    width: 98%;
  } */
}

@media (max-width: 550px) {
  table {
    width: 200vw !important;
  }
}

@media (max-width: 400px) {
  table {
    width: 250vw !important;
  }
}
