.poSection {
  display: flex;
  width: 100%;
}
.poListRow {
  width: 100%;
}
.search_add_wrapper {
  margin: 0 1.5rem 1rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_align_end {
  align-items: flex-end;
}

.d_dataTable,
.d_dataTable_sm {
  width: 98%;
  margin-left: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-20px);
}

.d_dataTable_items {
  position: relative;
  width: 98%;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.d_dataTable_sm {
  margin-top: 0;
}
.tabsScreens {
  width: 98%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 2.5rem;
  padding-bottom: 3rem;
}
.tabsScreens .tabHeading span {
  font-size: 22px;
  margin-bottom: 0.7rem;
  font-family: "gilroyBold";
}
.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100px);
}
.poEditRow {
  width: 100%;
}
.infoSection {
  width: 100%;
}
/* input form styles */
.poFormWrapper {
}
.subTitle {
  margin: 1rem;
  font-size: 16px;
  font-family: "GilroyMedium";
}
.poForm {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-auto-rows: auto auto;
  column-gap: 60px;
}
.field {
  width: 100%;
}
.poSubmitBtnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.poFormWrapper .borderBottom:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.customTextareaSpan {
  grid-column: span 2;
}
.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.resizeNone {
  resize: none;
}
.pdfWrapper {
  height: 100%;
}
.pdfLoader {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  height: calc(100% - 80px);
}
.itemParent {
  grid-template-columns: 1fr;
}
.itemChildren {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-auto-rows: auto auto;
  column-gap: 60px;
}
.totalPriceInput {
  max-width: 400px;
}
@media (max-width: 1400px) {
  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .users_topHeader {
    margin: 0;
    margin-left: 1rem;
  }

  .search_add_wrapper .rhsButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .d_dataTable {
    margin-left: 1rem;
  }

  .d_dataTable_sm {
    margin-left: 1rem;
  }

  .tabsScreens {
    margin-left: 1rem;
  }
}
@media (max-width: 800px) {
  .search_add_wrapper_content_left {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .search_add_wrapper_content_left .flex {
    flex-wrap: wrap;
    justify-content: unset;
  }

  .d_dataTable {
    align-items: center;
  }

  .d_dataTable_sm {
    align-items: center;
  }

  .tabsScreens {
    margin-left: 0;
  }
}
