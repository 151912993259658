.header_title_wrapper {
  color: var(--dashboard-heading-text-gray);
  margin: 1rem;
  margin-bottom: 0.8rem;
}
.header_title_wrapper span {
  font-size: 2.4rem;
  text-transform: capitalize;
  font-family: "gilroyBold";
}
