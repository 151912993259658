.search_wrapper {
  width: 30rem;
  height: 4.5rem;
  border-radius: 0.8rem;
  background-color: var(--bg-light);
  margin: 1rem;
  padding: 0 1rem;
}
.search_wrapper input {
  width: 85%;
  border: none;
  outline: none;
  padding: 0.6rem;
  font-size: 1.4rem;
  background-color: var(--bg-light);
}
.search_wrapper img {
  color: #000;
}
.search_wrapper div {
  margin: 0;
}
