.file_upload {
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  /* margin: 1rem; */
  border-radius: 6px;
  padding: 0 2.5rem;
  font-family: "GilroyMedium";
  background-color: #ffffff70;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2397979770' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='46' stroke-linecap='square'/%3e%3c/svg%3e");
}
.fileUploadWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.file_upload.drag_over {
  background-color: #f5f5f5;
}
.DocText {
  max-width: 50%;
  text-align: left;
}
.DocText span {
  word-wrap: break-word;
}
.file_upload span {
  font-size: 14px;
  margin: 0;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.file_upload button {
  background-color: #d8d8d840;
  margin-left: auto;
  color: #3a3b3c;
  /* padding: 8px 16px; */
  width: 106px;
  height: 31px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.file_upload p {
  font-size: 13px;
  color: #bababa;
  margin: 0;
}

.error_message {
  color: red;
  font-size: 1.2rem;
  margin: 0.3rem 0;
  font-family: "GilroyMedium";
  text-align: right;
}
