/* radio card styles */
.signupOptionsWrapper {
  width: 62rem;
  background-color: var(--bg-light);
  border-radius: 1rem;
  padding: 4rem 5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radioCard {
  border: 1px solid #ccc;
  padding: 0.2rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: border-color 0.3s ease;
}
.radioCard:hover {
  border-color: #3d4bd6;
}
.radio {
  display: none;
}
.cardHeading {
  font-weight: bold;
  margin-bottom: 8px;
}
.cardDesc {
  color: #777;
}
.active {
  border-color: #3d4bd6;
  background-color: #f0f8ff;
}
.checkIcon {
  color: #3d4bd6;
  margin-top: 8px;
  align-self: flex-end;
}
.cardHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardHeader span {
  font-family: "Source Serif Bold";
  font-size: 2.4rem;
}
.closeButtonIcon {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid #555;
  border-radius: 50%;
}
.radioCardSection {
  margin: 3rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
}
.radioCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 13rem;
  padding: 2rem;
}
.radioCard .cardHeading {
  font-size: 1.8rem;
  font-family: "Source Sans SemiBold";
}
.radioCard .cardDesc {
  font-size: 1.3rem;
  color: #adadad;
  font-family: "Source Sans Regular";
}
.radioCard .checkIcon {
  position: absolute;
  top: 2px;
  right: 10px;
  /* background-color: #3d4bd6; */
  border-radius: 50%;
  color: #fff;
}
.continueFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.continuePopupBtn {
  width: 45% !important;
  margin: 0 !important;
}
@media (max-width: 700px) {
  .signupOptionsWrapper {
    width: 90%;
    margin: 100px 0;
  }
  .continuePopupBtn {
    width: 100% !important;
    margin: 0 !important;
  }
}
