.superAdminContainer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 15px;
}
.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.propmtItemDesc {
  display: flex;
  flex: 1 1 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.users_topHeader {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promptScreenMain {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.promptScreen {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.propmtItem {
  width: 100%;
  padding: 1.4rem 2.4rem;
  background-color: #fff;
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
  display: flex;
}
.promptTitle {
  font-size: 14px;
  line-height: 1.2;
  font-family: "GilroyMedium";
  margin-bottom: 12px;
  font-weight: normal;
  text-align: right;
  flex: 1 1 auto;
  white-space: nowrap;
}
.promptDescription {
  font-size: 16px;
  line-height: 1.5;
  font-family: "GilroyMedium";
  flex: 1 1 100%;
  padding-right: 50px;
  margin: 0;
}
.cratePromptNotes {
  font-size: 1rem;
  line-height: 1.2;
  margin-left: 1rem;
  display: flex;
  position: relative;
  top: -3px;
}
.cratePromptNotes > span {
  margin-left: 0.5rem;
  text-align: left;
}
.crateBtnWrapper {
  flex: 1 1 150px;
}
.edtBtn {
  margin: 0;
}

.nodataInforWrapper {
}
.nodataInforWrapper > p {
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: "GilroyMedium";
}
.updatePopup {
}
.promptSkeleton:first-child {
  margin-top: 3rem;
}
.promptSkeleton {
  width: 100%;
  margin-bottom: 5rem;
}
.promptModalFram {
  margin: 0 auto;
}
.successPopupTitle {
  margin-bottom: 2rem;
}
@media (max-width: 1024px) {
  .propmtItemDesc {
    flex-direction: column-reverse;
  }
}
@media (max-width: 991px) {
  .crateBtnWrapper {
    flex: 1 1 auto;
  }
  .promptScreenMain {
    margin-top: 0rem;
  }
}
@media (max-width: 550px) {
  .propmtItemDesc {
    margin-bottom: 30px;
  }
  .propmtItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .crateBtnWrapper {
    flex: 1 1 auto;
  }
}
