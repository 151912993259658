.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-family: "gilroyMedium";
}

.pagination_button {
  background: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 0.5rem 0 0;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  /* border: 1px solid #55555530; */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.pagination_button img {
  width: 1.6rem;
  height: 1.6rem;
}

.pagination_text {
  margin: 0 1rem;
  font-size: 1.4rem;
}

.pagination_text span {
  background-color: rgb(238, 238, 238, 0.9);
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.pagination_text input {
  border: 1px solid rgb(136, 136, 136);
  width: 50px;
  background-color: rgb(238, 238, 238, 0.9);
  padding: 0.5rem 0.6rem;
  font-family: "gilroyMedium";
  border-radius: 4px;
}