.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_success_popup_wrapper {
  width: 60rem;
  height: 38rem;
  background-color: var(--bg-light);
  border-radius: 1rem;
  /* padding: 3rem 2rem; */
  padding: 0 2rem 2rem 2rem;
  flex-direction: column;
  text-align: center;
}
.popup_image {
  width: 30%;
}
.popup_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.popup_text .primary {
  font-size: 2.4rem;
  text-transform: capitalize;
  font-family: "NSSemiBold";
}
.popup_text p {
  font-size: 1.3rem;
  margin: 0;
  /* width: 90%; */
  text-align: center;
  margin-bottom: 1rem;
}
p.pDesc {
  font-size: 1.6rem;
  color: #555;
  margin-bottom: 0rem;
}
p.pDescLG {
  font-size: 1.4rem;
  /* margin: 0.5rem 0 1.5rem 0; */
  color: #555;
  width: 70%;
  margin-bottom: 1rem;
}
.signupText .primary {
  font-size: 3rem;
  font-family: "Source Sans Bold";
  margin-bottom: 0;
}
.signupText p {
  font-family: "Source Sans Regular";
  line-height: 18px;
  margin: 0;
  margin-bottom: 2rem;
}
.signupImg {
  margin-top: 10px;
  margin-bottom: 20px;
}
.submitBtn {
  width: 60%;
}
.absCenter .popupHeading {
  margin-bottom: 0;
}
.popupHeading div h2 {
  font-family: "gilroyBold";
}

@media (max-width: 500px) {
  .login_success_popup_wrapper {
    width: 90%;
    height: 28rem;
  }
  .signupText .primary {
    font-size: 2rem;
  }
  .signupText p {
    margin-bottom: 1.5rem;
  }
}
