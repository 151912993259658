.footer {
  background-color: #000000;
  color: #ffffff;
  padding: 4rem 10rem;
  display: flex;
  justify-content: space-between;
  min-height: 280px;
}
.footer .container {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerLogo {
  width: 20rem;
}

.logo {
  font-size: 20px;
}

.legalHeading {
  font-size: 18px;
  margin-bottom: 1rem;
  font-family: "Source Serif SemiBold";
}

.linkSection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
}

.link {
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 12px;
  font-size: 1.4rem;
  color: #ffffff70;
  padding: 0.3rem 0;
  font-family: "Source Sans Regular";
  cursor: pointer;
}

.bottomSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 2rem;
}

.socialMedia {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.icon {
  margin-right: 8px;
  text-decoration: none;
  color: #ffffff70;
  margin: 0 4rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: "Source Sans Regular";
}
.icon .smI {
  width: 3rem;
  height: 3rem;
  margin: 0;
  margin-right: 6px;
}

.icon .twit {
  width: 2.7rem;
  height: 2.7rem;
}

.socialMedia a:last-child .icon {
  margin-right: 0;
}

.socialMedia i {
  font-size: 20px;
}

.topSection .copyright {
  color: #ffffff70;
  font-size: 1.2rem;
  transform: translate(20px, 1px);
  font-family: "Source Sans Regular";
}
@media (min-width: 1300px) {
  .legalHeading,
  .link {
    font-size: 1.8rem;
  }
  .topSection .copyright {
    font-size: 1.4rem;
  }
  .icon {
    font-size: 1.5rem;
  }
}
@media (max-width: 800px) {
  .footer {
    padding: 4rem 3rem;
  }
  .bottomSection {
    align-items: baseline;
  }
  .bottomSection .socialMedia {
    flex-direction: column;
    align-items: flex-start;
  }
  .bottomSection .socialMedia .icon {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 500px) {
  .footer {
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    padding: 4rem 1rem;
  }
  .footer .container {
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
  }
  .bottomSection {
    margin-top: 2rem;
  }
  .topSection .copyright {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    bottom: -10%;
    text-align: center;
  }
  .footerLogo {
    width: 15rem;
  }
}
