.addUserPopup {
  width: 62rem;
  background-color: var(--bg-light);
  border-radius: 1rem;
  padding: 4rem 5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  margin: 100px 0;
}
.popup_fields {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.popup_action_btns_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: auto;
}
.popupHeading {
  margin-bottom: 0;
}
.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
@media (max-width: 700px) {
  .addUserPopup {
    width: 90% !important;
    padding: 2rem !important;
  }
}
