.profileWrapper {
  position: relative;
}
.profile {
  margin: 1rem 2rem;
  border-left: 0.15rem solid var(--login-gray);
  padding-left: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 14rem;
  cursor: pointer;
}
.profile .profile_image {
  width: 4.8rem;
  height: 4.8rem;
}
.profile_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile_text,
.profilecardSm .profile_text {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.profile_text .profile_name,
.profilecardSm .profile_text .profile_name {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "gilroySemiBold";
  text-transform: capitalize;
}
.profile_text p,
.profilecardSm .profile_text p {
  font-size: 1.4rem;
  font-family: "gilroyMedium";
  color: var(--dashboard-gray);
  margin: 0;
  line-height: 2rem;
  text-transform: capitalize;
}
.dropdown_menu {
  /* position: absolute; */
  padding: 1rem 2rem;
  /* top: 100%; */
  /* left: 60%; */
  /* transform: translateX(-50%); */
  list-style-type: none;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 6px 24px #00000017;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 18rem;
  z-index: 99;
  border-radius: 6px;
}
.dropdown_menu span {
  cursor: pointer;
  font-size: 16px;
  font-family: "gilroySemiBold";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 1.1rem;
  margin-left: 2rem;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #97979730;
  margin: 0.5rem 0;
}
.dropdown_menu span img {
  width: 20px;
}
.dropdown_menu span p {
  margin: 0;
  margin-left: 2rem;
  font-family: "gilroySemiBold";
}
.logoutPopup {
  height: 34.5rem;
  width: 62.8rem;
}
.logoutPopup h1 {
  margin-bottom: 1rem;
}
.logoutPopup img {
  width: 80%;
}
.settingsPopup {
  margin: 0 !important;
}
.profilecardSm {
  width: 110%;
  display: none;
  background-color: #adadad20;
  padding: 0.7rem;
  border-radius: 0.6rem;
  margin-bottom: 0.5rem;
}
.profile_text,
.profilecardSm .profile_text {
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;
}
.profile_text .profile_name,
.profilecardSm .profile_text .profile_name {
  font-size: 1.4rem;
  margin: 0;
  line-height: 13px;
}
.profile_text p,
.profilecardSm .profile_text p {
  font-size: 1.2rem;
}
@media (max-width: 1024px) {
  .profile {
    margin: 0 !important;
    margin-right: 1rem !important;
  }
  .settingsPopup {
    max-height: 500px;
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .profilecardSm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .profile {
    margin: 0;
    min-width: auto;
    margin-left: 1rem;
  }
  .profile .profile_image {
    width: 4rem;
    height: 4rem;
  }
  .profile_text {
    display: none;
  }
}
@media (max-width: 600px) {
  .pwds {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .dropdown_menu span {
    margin: 0.8rem 0;
    font-size: 1.4rem;
    margin-left: 1.5rem;
  }
  .dropdown_menu span img {
    width: 2rem;
    height: 2rem;
  }
  .dropdown_menu span p {
    margin-left: 1.5rem;
  }
}
