.datebar_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  width: auto;
  padding: 0 2rem;
  height: 4.8rem;
  border-radius: 1.1rem;
  background-color: var(--bg-light);
  margin: 1rem;
}
.datebar_wrapper p {
  margin: 0;
  margin-left: 1rem;
  font-family: "gilroySemiBold";
}
.datebar_wrapper img {
  width: 2rem;
  height: 2rem;
}
@media (max-width: 768px) {
  .datebar_wrapper {
    display: none;
  }
}
