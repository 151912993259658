.imgViewWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 1rem;
  width: 25rem;
  margin-bottom: 4rem;
}
.imgField {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 20rem;
  height: 20rem;
  border-radius: 14px;
  padding: 1rem;
  border: 1.5px solid #979797;
  margin-bottom: 1rem;
}
.imgViewWrapper .label {
  font-size: 16px;
  font-family: "GilroyMedium";
  width: 20rem;
}
.viewfullImageIcon,
.downloadFileIcon {
  display: none !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 35px;
  color: #555;
  font-size: 20px !important;
  z-index: 9;
}
.downloadFileIcon {
  right: 10px;
}
.imgField:hover .viewfullImageIcon {
  display: inline !important;
}
.imgField:hover .downloadFileIcon {
  display: inline !important;
}
.fullImageViewWrap {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  margin: auto;
  border-radius: 10px;
}
.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px !important;
  color: #131313;
  cursor: pointer !important;
}
.fullImageView {
  padding: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pdfPage {
  width: 100% !important;
  height: 100vh !important;
}
.pdfPage canvas {
  width: 100% !important;
  height: 100% !important;
}
.pdfWrapper {
  margin: auto;
  width: auto;

  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdfBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.pdfBtn .button {
  background: #eee;
  border: 0;
  outline: 0;
  margin-left: 0.5rem;
  cursor: pointer;
}
.pdfWrapper p,
.pdfBtn p {
  font-size: 1.4rem;
  font-family: "gilroyMedium";
}
.pdfWrapper p {
  margin-bottom: 1rem;
}
