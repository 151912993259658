.rc_wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: var(--bg-light);
  padding: 2rem;
  border-radius: 8px;
  height: 125px;
  /* margin-right: 2rem; */
  margin-bottom: 2rem;
  /* max-width: 345px; */
  overflow: hidden;
}
.title,
.value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  margin-bottom: 1rem;
}
.title img {
  width: 42px;
  height: 42px;
}
.title span {
  font-size: 13px;
  font-family: "GilroyMedium";
  color: #3a3a3c;
  padding-right: 2rem;
  max-width: 150px;
}
.value p {
  margin: 0;
  font-size: 20px;
  font-family: "gilroyBold";
  color: #2d426e;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.value .grade {
  font-size: 12px;
  font-family: "GilroyMedium";
  width: 65px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #71dd3726;
  color: #499221;
  border-radius: 4px;
}
