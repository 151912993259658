.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_dropdown {
  position: relative;
  display: inline-block;
  margin: 0 1.5rem;
}

.dropdown_toggle {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  border: 1.5px solid #adadad80;
  padding: 0.8rem 2.8rem;
  border-radius: 18px;
  color: #333;
  font-family: "Source Sans Regular";
}
.dropdown_toggle:hover {
  background-color: transparent !important;
}
.dropdown_menu {
  position: absolute;
  /* height: 8rem; */
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  margin: 0;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: max-content;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown_menu li {
  width: 100%;
  border-radius: 3px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: "Source Sans Regular";
  border-radius: 3px;
}

.dropdown_menu li:hover {
  background-color: #3d4bd610;
}
.signup_btn {
  border-radius: 50px;
  overflow: hidden;
  background-color: #3d4bd6;
  color: var(--text-light);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1.5rem 4rem;
  margin: 1rem;
  height: 50px;
  font-family: "Source Sans Regular";
}
.signup_btn span {
  font-size: 1.8rem;
}
.signup_btn .btnImg {
  font-size: 30px;
  margin-left: 1rem;
}
.signup_btn .signup_btn_img {
  margin-left: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: transparent;
}
.signup_btn.disabled {
  cursor: auto;
  opacity: 0.8;
}
.signup_btn_img {
  vertical-align: middle;
}
@media (max-width: 991px) {
  .dropdown_toggle {
    margin-bottom: 2rem !important;
  }
  .dropdown_menu {
    left: 0;
    transform: translateX(0);
  }
}
@media (max-width: 300px) {
  .signup_btn span {
    font-size: 1.5rem;
  }
}
