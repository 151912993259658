.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}
.popupHeading {
  margin-bottom: 0;
  font-size: 24px;
}
.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.VUpopupHeading {
  margin-bottom: 1.5rem;
  font-size: 24px;
}
.VUpopupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.users_heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;
}
.users_filters {
  align-items: flex-start;
}
.search_add_wrapper {
  margin: 0 1.5rem 2rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d_dataTable {
  width: 98%;
  margin-left: 2.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 5rem;
  margin: 0 !important;
}
.InviteVendorPopup {
  margin: 0 !important;
}
.centerDiv {
  margin: 1rem 0;
}
.ViewVendorHeader {
  width: 95%;
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1.5px solid #97979770;
}
.tabsScreens {
  width: 98%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 2.5rem;
  margin-left: 2.5rem;
  margin-bottom: 2rem;
}
.infoSection {
  width: 100%;
  margin-bottom: 1rem;
}
.infoSection .info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 22rem);
  gap: 10px;
}
.infoSection .infoImageFrame {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 25rem);
  gap: 10px;
}
.tabsScreens .tabHeading span {
  font-size: 16px;
  margin-bottom: 0.7rem;
  font-family: "gilroyBold";
}
.wrap {
  flex-wrap: wrap;
}
.fullImageViewWrap {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  margin: auto;
  border-radius: 10px;
}
.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px !important;
  color: #131313;
  cursor: pointer !important;
}
.fullImageView {
  padding: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.fields {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
}
.linkBtn {
  color: #0000ffac;
  font-size: 1.4rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: "GilroyMedium";
  text-decoration: underline;
}
.popupTxtSm {
  max-width: 30rem;
}
/* ===== compare table role based UI ===== */
.stickyHead{
  /* position: sticky !important;
  top: 0;
  left: 0; */
  width: 200px;
}
.compareResultTable{
  border: 1px solid #eee;
  overflow: hidden;
}
.compareResultTable body tr th{
  width: 200px;
}
th.defaultHead {
  background-color: #f5f2ff;
}
th.buyerHead {
  background-color: var(--buyerSideBar-bg-light-blue);
  white-space: pre;
}
th.vendorHead {
  background-color: var(--vendor-table--blue);
}
.processedTable tr td{
  border-left: 1px solid #eee;
}
@media (max-width: 1024px) {
  /* .users_tab_wrapper {
    width: 98%;
  } */
  .fields {
    flex-wrap: wrap;
  }
  .addVendorPopupInput {
    width: 100% !important;
  }
  .InviteVendorPopup {
    max-height: 500px;
    overflow: auto;
  }
  .users_topHeader {
    margin: 0;
    margin-left: 1rem;
  }
  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
  .d_dataTable {
    margin-left: 1rem;
  }
  .search_add_wrapper .flex {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .ViewVendorHeader {
    margin-left: 1.5rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .tabsScreens {
    margin-left: 1rem;
  }
  .infoSection .info {
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  }
  .infoSection .infoImageFrame {
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  }
}

@media (max-width: 500px) {
  .d_dataTable {
    align-items: center;
  }
}
