/* RESETTING CSS DEFAULT STYLES */
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: all 0.1s; */
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

/* body  */
body {
  font-family: "NSRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 62.5%;
  line-height: 1.5;
  /* a nice line-height */
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  box-shadow: inset 0 0 20px 20px #23232300;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -ms-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
}

/* ROOT VARIABLES */
:root {
  /* login page color */
  --login-gray-text: #3a3a3c85;
  --login-gray: #3a3a3c33;
  --login-btn-blue: #009bdd;
  --login-btn-d-blue: #2696e6;
  --login-btn-pink: #9887ff;
  --login-text-dark: #000;
  --login-text-light: #fff;
  --login-text-danger: #ff0000;
  --fp-link: #075b97;

  /* signup page */
  --signup-green-dark: #4895a1;
  --signup-green-light: #96c8d0;

  /* common  bgcolors */
  --bg-light: #fff;
  --bg-dark: #000;
  --text-light: #fff;
  --text-dark: #000;

  /* sidebar colors */
  --sidebar-bg-blue: #8ec5fc;
  --sidebar-bg-pink: #e0c3fc;
  --sidebar-tab-hover: #ffffff60;
  --sidebar-tab-text-color: #324671;

  /* buyer sidebar */
  --buyerSideBar-bg-light-blue: #e0e9ee;
  --buyerSideBar-bg-active-blue: #e9eff3;
  --buyerSideBar-btn-primary-blue: #3b86c7;
  --buyerSideBar-table-blue: #e1eaef;

  /* vendor sidebar color */
  --vendorSideBar-bg-primary-blue: #1c92d2;
  --vendorSideBar-bg-secondary-blue: #a6f0fe;
  --vendorSideBar-bg-active-blue: #ffffff55;
  --vendorSideBar-btn-primary-blue: #1e92d2;
  --vendor-table--blue: #edfcff;
  /* dashboard related colors */
  --dashboard-darkblue: #324671;
  --carousel-bg-blue: #8ec5fc;
  --carousel-bg-pink: #e0c3fc;
  --org-dashboard-bg-gray: #fafafa;
  --dashboard-gray: #999999;
  --dashboard-heading-text-gray: #3a3a3c;
  --dsb-filter-label-gray: #3a3a3c;
  --dsb-table-border-gray: #97979720;
}

/* fonts for dashboard */
@font-face {
  font-family: "NSBold";
  src: url("./assets/fonts/static/nutino_sans_origin/NunitoSans-Bold.ttf");
}

@font-face {
  font-family: "NSExtraBold";
  src: url("./assets/fonts/static/nutino_sans_origin/NunitoSans-ExtraBold.ttf");
}

@font-face {
  font-family: "NSRegular";
  src: url("./assets/fonts/static/nutino_sans_origin/NunitoSans-Regular.ttf");
}

@font-face {
  font-family: "NSSemiBold";
  src: url("./assets/fonts/static/nutino_sans_origin/NunitoSans-SemiBold.ttf");
}

/* dashboard gilroy font */
@font-face {
  font-family: "GilroyBold";
  src: url("./assets/fonts/static/gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "GilroyExtraBold";
  src: url("./assets/fonts/static/gilroy/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: "GilroyMedium";
  src: url("./assets/fonts/static/gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "GilroyRegular";
  src: url("./assets/fonts/static/gilroy/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "GilroySemiBold";
  src: url("./assets/fonts/static/gilroy/Gilroy-SemiBold.ttf");
}

/* Source serif font faces */
/* Regular */
/* Regular */
@font-face {
  font-family: "Source Serif Regular";
  src: url("./assets/fonts/static/source_serif_pro/SourceSerif4-Regular.ttf");
}

/* Bold */
@font-face {
  font-family: "Source Serif Bold";
  src: url("./assets/fonts/static/source_serif_pro/SourceSerif4-Bold.ttf");
}

/* SemiBold */
@font-face {
  font-family: "Source Serif SemiBold";
  src: url("./assets/fonts/static/source_serif_pro/SourceSerif4-SemiBold.ttf");
}

/* ExtraBold */
@font-face {
  font-family: "Source Serif ExtraBold";
  src: url("./assets/fonts/static/source_serif_pro/SourceSerif4-ExtraBold.ttf");
}

/* Regular */
@font-face {
  font-family: "Source Sans Regular";
  src: url("./assets/fonts/static/source_sans_pro/SourceSans3-Regular.ttf");
}

/* SemiBold */
@font-face {
  font-family: "Source Sans SemiBold";
  src: url("./assets/fonts/static/source_sans_pro/SourceSans3-SemiBold.ttf");
}

/* Bold */
@font-face {
  font-family: "Source Sans Bold";
  src: url("./assets/fonts/static/source_sans_pro/SourceSans3-Bold.ttf");
}

/* Extra Bold */
@font-face {
  font-family: "Source Sans Extra Bold";
  src: url("./assets/fonts/static/source_sans_pro/SourceSans3-ExtraBold.ttf");
}

/* COMMON COMPONENTS STYLES */

/* brand logo styling */
.br_logo {
  margin: 1rem 2rem;
}

.br_logo img {
  width: 100%;
  object-fit: contain;
}

.main {
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}