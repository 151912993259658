.btnWrap {
  cursor: pointer;
  min-width: 25rem;
  padding: 1rem 2rem;
  margin: 0;
  margin-top: 0.6rem;
  border-radius: 6px;
  border: 1px solid #adadad;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnWrap span {
  margin-right: 1rem;
  font-size: 1.5rem;
  font-family: "gilroyMedium";
}
