.dashboard_wrapper {
  background-color: var(--org-dashboard-bg-gray);
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
}
.dashboard_wrapper .mainContent {
  width: 79%;
}
.dashboard_wrapper .mainContentClose {
  width: 97%;
}
.logoutPopup {
  height: 34.5rem;
  width: 62.8rem;
}
.logoutPopup h1 {
  margin-bottom: 2rem;
}
.logoutPopup img {
  width: 80%;
}
.mainContentSuperAdmin.mainContentClose{
  width: 100%;
}
@media (max-width: 1024px) {
  .mainContent {
    width: 98% !important;
  }
  .dashboard_wrapper {
    flex-direction: column;
  }
}
