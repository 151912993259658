.prp_wrapper {
  font-family: "Source Sans Regular";
  margin: 2rem 7rem;
  margin-top: 11rem;
  text-align: left;
  max-width: 1600px;
  padding: 0 6rem;
  margin-left: auto;
  margin-right: auto;
}
.prp_wrapper .privacy_header {
  background-color: #adadad30;
  border-radius: 5px;
  padding: 3rem;
  width: 100%;
  font-size: 1.5rem;
}
.privacy_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.5rem;
}
.prp_wrapper .privacy_content div {
  margin: 2rem 0;
}
.prp_wrapper table {
  border: 1px solid #adadad60;
  margin: 2rem 0;
  border-collapse: collapse;
}
.prp_wrapper table td,
.prp_wrapper table th {
  border: 1px solid #adadad60;
  width: 25%;
  font-size: 1.3rem;
  line-height: 18px;
}

@media (max-width: 700px) {
  .prp_wrapper {
    padding: 0rem 3rem;
  }
}
@media (max-width: 500px) {
  .prp_wrapper {
    padding: 0rem 2rem;
    margin-top: 7rem;
  }
}
