.users_tab_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.users_topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}
.popupHeading {
  margin-bottom: 0;
  font-size: 24px;
}
.popupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.VUpopupHeading {
  margin-bottom: 1.5rem;
  font-size: 24px;
}
.VUpopupHeading h2 {
  font-family: "gilroyBold";
  font-size: 24px;
}
.users_heading_inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;
}
.users_filters {
  align-items: flex-start;
}
.search_add_wrapper {
  margin: 0 1.5rem 2rem 1.5rem;
  padding-right: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d_dataTable {
  width: 98%;
  margin-left: 2.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.VUPopup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 5rem;
  margin: 0 !important;
}
.centerDiv {
  margin: 1rem 0;
}
.dltConfirmationPopup {
  width: 50rem !important;
  height: 32rem !important;
}
.dltConfirmationPopup span {
  padding: 0 2rem !important;
}
.dltedPopup {
  width: 50rem !important;
  height: 32rem !important;
}
.dltedPopup span {
  padding: 0 2rem !important;
}
.dltedPopup .popup_image {
  width: 26%;
}
.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
}
.linkBtn {
  color: #0000ffac;
  font-size: 1.4rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: "GilroyMedium";
  text-decoration: underline;
}
.popupTxtSm {
  max-width: 35rem;
}
.updatePopup {
  margin: 0 !important;
}
.addPopup {
  margin: 0 !important;
}
@media (max-width: 1024px) {
  /* .users_tab_wrapper {
    width: 97%;
  } */
  .updatePopup,
  .addPopup {
    max-height: 500px;
    overflow: auto;
    margin: 0 !important;
  }
  .VUPopup {
    max-height: 500px;
    overflow: auto;
  }
  .users_topHeader {
    margin: 0;
    margin-left: 1rem;
  }
  .search_add_wrapper {
    flex-wrap: wrap;
    margin: 0;
  }
  .d_dataTable {
    margin-left: 1rem;
  }
  .search_add_wrapper .rhsButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .d_dataTable_sm {
    margin-left: 1rem;
  }
  .tabsScreens {
    margin-left: 1rem;
  }
}
@media (max-width: 800px) {
  .search_add_wrapper {
    flex-wrap: wrap;
  }
}
@media (max-width: 600px) {
  .popupFields {
    width: 100% !important;
  }
}
