.label_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0.8rem 1rem;
}

.label_container label {
  font-size: 1.4rem;
  color: #a4a4a4;
  font-family: "GilroyMedium";
  margin: 0 0 0.6rem 0;
}
.label_container p {
  width: auto;
  max-width: 100%;
  margin: 0;
  font-size: 1.5rem;
  font-family: "GilroyMedium";
  word-break: break-all;
  text-align: left;
  max-height: 400px;
  overflow: auto;
}
.readMore {
  cursor: pointer;
  font-size: 1.4rem;
  text-decoration: underline;
  font-family: "gilroySemiBold";
  color: #0000ffac;
}
