.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--bg-light);
  z-index: 9;
  padding: 2rem 5rem;
  height: 10rem;
  border-bottom: 1px solid #adadad50;
  justify-content: space-between;
}
.header .container {
  width: 100%;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.links.responsive,
.MenuLinks.responsive {
  flex-direction: column;
  align-items: flex-start;
}
.MenuLinks {
  flex-direction: column;
  align-items: flex-start;
}
.menuTab {
  width: 30rem;
  padding: 2.5rem 2rem;
  position: relative;
}
.MenuLinks li {
  margin: 1rem 0;
}
.links li,
.MenuLinks li {
  list-style-type: none;
  cursor: pointer;
}
.links li a,
.procurement_section_tabs li a,
.MenuLinks li a {
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-family: "Source Sans Regular";
  margin: 0 2rem;
  font-size: 1.6rem;
  color: #1c1c1c;
  transition: none;
}
/* .links li a:hover,
.MenuLinks li a:hover {
  color: blue;
  border-bottom: 0.15rem solid blue;
  transition: none;
} */
.menuOpener {
  display: none;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 1.5rem;
}
.menuOpener .menuOpenerIcon,
.closeButton .closeButtonIcon {
  font-size: 3rem;
}
.closeButton {
  position: absolute;
  top: 2.8rem;
  right: 2.5rem;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 3rem;
}
.menuDraw {
  display: none;
}
.menuTab .lgBtn {
  margin-top: 1rem;
}

@media (min-width: 1500px) {
  .header {
    padding: 2rem 9rem 2rem 11rem;
  }
}
@media (min-width: 1300px) {
  .links li a,
  .MenuLinks li a {
    font-size: 1.8rem;
  }
}
@media (max-width: 991px) {
  .links {
    display: none;
  }
  .menuOpener {
    display: block;
  }
  .menuDraw {
    display: initial;
  }
}
@media (max-width: 700px) {
  .header {
    padding: 2rem;
  }
}
@media (max-width: 500px) {
  .header {
    padding: 2rem 1rem;
    height: 6.5rem;
  }
  .menuOpener .menuOpenerIcon,
  .closeButton .closeButtonIcon {
    font-size: 2.5rem;
  }
  .closeButton {
    top: 2.5rem;
  }
  .header .logo {
    width: 13rem;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 400px) {
  .header {
    padding: 2rem 0.5rem;
    height: 6, 5rem;
  }
}
