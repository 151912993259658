.d_button,
.d_button_outlined {
  margin: 1rem;
  height: 4rem;
  padding: 0 2rem;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  background-color: var(--dashboard-darkblue);
  color: var(--text-light);
}
.d_button span,
.d_button_outlined span,
.buyerBtn span,
.buyerBtnOutlined span,
.vendorBtn span,
.vendorBtnOutlined span {
  font-size: 1.4rem;
  font-family: "gilroySemiBold";
}
.d_button_outlined {
  background-color: transparent;
  color: #3a3a3c;
  border: 0.1rem solid var(--dashboard-gray);
}
.buyerBtn,
.buyerBtnOutlined {
  margin: 1rem;
  margin-bottom: 1.1rem;
  height: 4rem;
  padding: 0 2rem;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  background-color: var(--buyerSideBar-btn-primary-blue);
  color: var(--text-light);
}
.vendorBtn,
.vendorBtnOutlined {
  margin: 1rem;
  margin-bottom: 1.1rem;
  height: 4rem;
  padding: 0 2rem;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  background-color: var(--vendorSideBar-btn-primary-blue);
  color: var(--text-light);
}
.buyerBtnOutlined {
  background-color: transparent;
  color: var(--buyerSideBar-btn-primary-blue);
  border: 0.1rem solid var(--buyerSideBar-btn-primary-blue);
}
.vendorBtnOutlined {
  background-color: transparent;
  color: var(--vendorSideBar-btn-primary-blue);
  border: 0.1rem solid var(--vendorSideBar-btn-primary-blue);
}
.dashboard_button_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.error_message {
  color: red;
  font: 1.2rem/1.8rem "GilroyMedium";
  margin: 0;
  margin-right: 1.3rem;
  margin-top: -0.7rem;
  max-width: 20rem;
  text-align: right;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
@media (max-width: 600px) {
  .d_button,
  .d_button_outlined,
  .buyerBtn,
  .vendorBtn,
  .buyerBtnOutlined,
  .vendorBtnOutlined {
    width: max-content !important;
    min-width: 10rem !important;
    transform: scale(0.9);
  }
  .d_button span,
  .d_button_outlined span,
  .buyerBtn span,
  .vendorBtn span,
  .buyerBtnOutlined span,
  .vendorBtnOutlined span {
    font-size: 1.3rem !important;
  }
}
