.switch_checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 34px;
  height: 17px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch_label .switch_button {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  width: 38%;
  height: 76%;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch_checkbox:checked + .switch_label .switch_button {
  left: calc(100% - 2px);
  transform: translateX(-100%) translateY(-50%);
}

.switch_label:active .switch_button {
  width: 43%;
}
